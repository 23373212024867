import React, {Component} from "react";
import Sheet from "./Sheet";
import Report from "./Report";

class Timesheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected: 'sheet',
        }
        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="dashboard-banking">
                <div className="grid">
                    <div className="col-12 md:col-4 lg:col-4 overview-boxes" onClick={this.showBox('sheet')}
                         style={{cursor: 'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <i className="fa fa-calendar mt-5 ml-5"
                                   style={{'fontSize': '2.5em', color: "#FFFFFF"}}></i>
                            </div>
                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Timesheet</span>
                            </div>
                        </div>
                    </div>

                </div>
                {(this.state.opcionMenuSelected === 'sheet') && <Sheet/>}
                {(this.state.opcionMenuSelected === 'report') && <Report/>}
            </div>
        );
    }
}

export default Timesheet;
