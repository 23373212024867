import React, { Component } from 'react';
import {Toast} from "primereact/toast";
import {ProgressSpinner} from "primereact/progressspinner";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {DateService} from "../../service/util/DateService";

class Contacts extends Component {

    standardHours = [
        {shr: '00:00:00', shr1: '00:00:00', shr2: '00:00:00', value1: 'ST'},
        {shr: '01:00:00', shr1: '01:00:00', shr2: '01:00:00', value1: 'ST'},
        {shr: '02:00:00', shr1: '02:00:00', shr2: '02:00:00', value1: 'ST'},
        {shr: '03:00:00', shr1: '03:00:00', shr2: '03:00:00', value1: 'ST'},
        {shr: '04:00:00', shr1: '04:00:00', shr2: '04:00:00', value1: 'ST'},
        {shr: '05:00:00', shr1: '05:00:00', shr2: '05:00:00', value1: 'ST'},
        {shr: '06:00:00', shr1: '06:00:00', shr2: '06:00:00', value1: 'ST'},
        {shr: '07:00:00', shr1: '07:00:00', shr2: '07:00:00', value1: 'ST'},
        {shr: '08:00:00', shr1: '08:00:00', shr2: '08:00:00', value1: 'ST'},
        {shr: '09:00:00', shr1: '09:00:00', shr2: '09:00:00', value1: 'ST'},
        {shr: '10:00:00', shr1: '10:00:00', shr2: '10:00:00', value1: 'ST'},
        {shr: '11:00:00', shr1: '11:00:00', shr2: '11:00:00', value1: 'ST'},
        {shr: '12:00:00', shr1: '12:00:00', shr2: '12:00:00', value1: 'ST'},
        {shr: '13:00:00', shr1: '13:00:00', shr2: '13:00:00', value1: 'ST'},
        {shr: '14:00:00', shr1: '14:00:00', shr2: '14:00:00', value1: 'ST'},
        {shr: '15:00:00', shr1: '15:00:00', shr2: '15:00:00', value1: 'ST'},
        {shr: '16:00:00', shr1: '16:00:00', shr2: '16:00:00', value1: 'ST'},
        {shr: '17:00:00', shr1: '17:00:00', shr2: '17:00:00', value1: 'ST'},
        {shr: '18:00:00', shr1: '18:00:00', shr2: '18:00:00', value1: 'ST'},
        {shr: '19:00:00', shr1: '19:00:00', shr2: '19:00:00', value1: 'ST'},
        {shr: '20:00:00', shr1: '20:00:00', shr2: '20:00:00', value1: 'ST'},
        {shr: '21:00:00', shr1: '21:00:00', shr2: '21:00:00', value1: 'ST'},
        {shr: '22:00:00', shr1: '22:00:00', shr2: '22:00:00', value1: 'ST'},
        {shr: '23:00:00', shr1: '23:00:00', shr2: '23:00:00', value1: 'ST'}

    ];


    constructor(props) {
        super(props);

        this.state = {
            totalHours: [
                {thr: '00:00:00', value3: '12am', time: 'Over Time', time2: 'Over Time'},

                {thr: '01:00:00', value3: '1am', time: 'Over Time', time2: 'Over Time'},

                {thr: '02:00:00', value3: '2am', time: 'Over Time', time2: 'Over Time'},

                {thr: '03:00:00', value3: '3am', time: 'Over Time', time2: 'Over Time'},

                {thr: '04:00:00', value3: '4am', time: 'Over Time', time2: 'Over Time'},

                {thr: '05:00:00', value3: '5am', time: 'Over Time', time2: 'Over Time'},

                {thr: '06:00:00', value3: '6am', time: 'Over Time', time2: 'Over Time'},

                {thr: '07:00:00', value3: '7am', time: 'Over Time', time2: 'Over Time'},

                {thr: '08:00:00', value3: '8am', time: 'Standard Time', time2: 'Over Time'},

                {thr: '09:00:00', value3: '9am', time: 'Standard Time', time2: 'Over Time'},

                {thr: '10:00:00', value3: '10am', time: 'Standard Time', time2: 'Over Time'},

                {thr: '11:00:00', value3: '11am', time: 'Standard Time', time2: 'Over Time'},

                {thr: '12:00:00', value3: '12pm', time: 'Standard Time', time2: 'Over Time'},

                {thr: '13:00:00', value3: '1pm', time: 'Standard Time', time2: 'Over Time'},

                {thr: '14:00:00', value3: '2pm', time: 'Standard Time', time2: 'Over Time'},

                {thr: '15:00:00', value3: '3pm', time: 'Standard Time', time2: 'Over Time'},

                {thr: '16:00:00', value3: '4pm', time: 'Standard Time', time2: 'Over Time'},

                {thr: '17:00:00', value3: '5pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '18:00:00', value3: '6pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '19:00:00', value3: '7pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '20:00:00', value3: '8pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '21:00:00', value3: '9pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '22:00:00', value3: '10pm', time: 'Over Time', time2: 'Over Time'},

                {thr: '23:00:00', value3: '11pm', time: 'Over Time', time2: 'Over Time'}


            ],
            rawReport: [],
            events: [],
            chargedYet: false,
            eventCount: 0,
            selectedStHours: [],
            selectedStHours2: [],
            report: {
                id: null,
                st_start: "",
                st_end: "",
                st_day: "",
                ot_start: "",
                ot_end: "",
                ot_day: ""
            }

        };

        this.getStandardHours = this.getStandardHours.bind(this);
        this.restartHours = this.restartHours.bind(this);
        this.updateHours = this.updateHours.bind(this);
        this.updateHoursV2 = this.updateHoursV2.bind(this);
        this.onStHourChange = this.onStHourChange.bind(this);
        this.onStHourChange2 = this.onStHourChange2.bind(this);
        this.getSelectedHour = this.getSelectedHour.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.actionSettings = this.actionSettings.bind(this);
        this.actionSettingsOt = this.actionSettingsOt.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.dateService = new DateService();
    }

    componentDidMount() {
        debugger
        this.getStandardHours();
        this.getSelectedHour();
    }

    getStandardHours() {
        debugger
        this.setState({chargedYet: false});
        this.worxflowServices.getSettings().then(resp => {
            if (resp.status === "OK") {
                const old_configuration = this.state.totalHours;
                const st_start = resp.data.st_init;
                const st_end = resp.data.st_end;

                old_configuration.forEach(element => {
                    const hour = element.thr
                    if (hour >= st_start && hour <= st_end) {
                        element.time = 'Standard Time'
                        console.log(old_configuration)
                    } else if (hour < st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else if (hour > st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else {
                        element.time = ''
                    }

                })

                console.log(old_configuration)
                console.log(this.state.totalHours)
                debugger
                this.setState({rawReport: old_configuration})
                this.toast.show({severity:'success', summary: 'Load Success'});
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }

    restartHours() {
        debugger
        this.setState({chargedYet: false});
        const params = {
            st_init : this.standardHours[8].shr1,
            st_end : this.standardHours[17].shr1
        }
        this.worxflowServices.updateSettings(params).then(resp => {
            if (resp.status === "OK") {
                const old_configuration = this.state.totalHours;
                const st_start = this.state.selectedStHours.shr1;
                const st_end = this.state.selectedStHours2.shr2;
                old_configuration.forEach(element => {
                    const hour = element.thr
                    if (hour >= st_start && hour <= st_end) {
                        element.time = 'Standard Time'
                        console.log(old_configuration)
                    } else if (hour < st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else if (hour > st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else {
                        element.time = ''
                    }

                })
                console.log(old_configuration)
                console.log(this.state.totalHours)
                debugger
                this.setState({rawReport: old_configuration
                })
                this.toast.show({severity:'success', summary: 'Successful Hours Update'});
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }

    updateHoursV2() {
        debugger
        this.setState({chargedYet: false});
        const params = {
            st_init : this.state.selectedStHours.shr1,
            st_end : this.state.selectedStHours2.shr2
        }
        this.setState({chargedYet: false});
        this.worxflowServices.updateSettings(params).then(resp => {
            if (resp.status === "OK") {

                const old_configuration = this.state.totalHours;
                const st_start = this.state.selectedStHours.shr1;
                const st_end = this.state.selectedStHours2.shr2;
                old_configuration.forEach(element => {
                    const hour = element.thr
                    if (hour >= st_start && hour <= st_end) {
                        element.time = 'Standard Time'
                        console.log(old_configuration)
                    } else if (hour < st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else if (hour > st_start) {
                        element.time = 'Over Time'
                        console.log(old_configuration)
                    } else {
                        element.time = ''
                    }

                })
                console.log(old_configuration)
                console.log(this.state.totalHours)
                debugger
                this.setState({rawReport: old_configuration
                })
                this.toast.show({severity:'success', summary: 'Successful Hours Update'});
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }


    updateHours() {
        debugger

        const st_start = this.state.selectedStHours.shr1;
        const st_end = this.state.selectedStHours2.shr2;
        const old_configuration = this.state.rawReport;
        old_configuration.forEach(element => {
            const hour = element.thr
            if (hour >= st_start && hour <= st_end) {
                element.time = 'Standard Time'
                console.log(old_configuration)
            } else if (hour < st_start) {
                element.time = 'Over Time'
                console.log(old_configuration)
            } else if (hour > st_start) {
                element.time = 'Over Time'
                console.log(old_configuration)
            } else {
                element.time = ''
            }
        })
        console.log(old_configuration)
        console.log(this.state.totalHours)
        debugger
        this.setState({rawReport: old_configuration})
    }

    getSelectedHour= event => {
        debugger
        if (event === undefined) {
        this.worxflowServices.getSettings().then(resp => {
            if (resp.status === "OK") {
                const rawHour = [];
                const old_configuration = this.state.totalHours;
                const st_start = resp.data.st_init;
                const st_end = resp.data.st_end;

                console.log(old_configuration)
                console.log(st_start)
                console.log(st_end)

                const st_hours = this.standardHours
                st_hours.forEach(element => {
                    if (element.shr === st_start) {
                        element.shr = st_start
                        rawHour.push(element);
                        console.log(rawHour)
                    }
                    if (element.shr === st_end) {
                        element.shr = st_end
                        rawHour.push(element);
                        console.log(rawHour)
                    }

                })
                this.setState({
                    selectedStHours: rawHour[0],
                    selectedStHours2: rawHour[1]
                });
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
        }
    }

    onStHourChange= event => {
        debugger

        if (event.value.shr1 !== undefined) {
            this.setState({
                selectedStHours: event.value,
                selectedStHours2: event.value
            });


                const st_hours = this.standardHours
                let st_end1 = event.value.shr1
                let st_end2 = event.value.shr2
                console.log(st_hours)
                console.log(st_end1)
                console.log(st_end2)
                st_hours.forEach(e => {
                    if (e.shr1 < st_end1) {
                        e.shr = ''
                        console.log(st_hours)
                    }
                })

                st_hours.forEach(e2 => {
                    if (e2.shr1 >= st_end1) {
                        e2.shr = e2.shr2
                        console.log(st_hours)
                    }
                })




            this.setState({

                selectedStHours2: event.value
            });
        }
    }

    onStHourChange2= event => {
        debugger
            const st_start = event.value.shr1
            const st_end = event.value.shr2
            console.log(st_start)
            console.log(st_end)

            this.setState({

                selectedStHours2: event.value
            });

    }




    clearFilters = () => {
        debugger
        this.setState({
            selectedStHours: this.standardHours[8],
            selectedStHours2: this.standardHours[17]

        })
        this.restartHours();
    }

    actionSettings (rawReport) {
        if(rawReport.time === "Standard Time") {
            return <span style={{color: "#008000",backgroundColor: "#ccffc4", padding: '5px 10px', borderRadius: '5px'}}>Standar Time</span>;
        } else if (rawReport.time === "Over Time"){
            return <span style={{color: "#000080", backgroundColor: "#ced5ff", padding: '5px 10px', borderRadius: '5px'}}>Over Time</span>;
        } else {
            return <Button label="" className="p-button-success"/>;
        }


    }

    actionSettingsOt (rawReport) {
        if(rawReport.time2 === "Over Time") {
            return <span style={{color: "#000080", backgroundColor: "#ced5ff", padding: '5px 10px', borderRadius: '5px'}}>Over Time</span>;
        }
    }


    render() {
        return (

            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el}/>
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".7s"/>
                        </div>
                    }
                </div>
                <Card height="height-200">
                    <div className="grid">
                        <div className="col-12 md:col-20" style={{paddingTop: '0px'}}>
                            <div className="p-fluid grid" style={{paddingLeft: '80px'}}>
                                <div className="col-2 md:col-2">
                                    <Button label="Select Standard Time" className="p-button-success"/>
                                </div>


                                <div className="col-12 md:col-2">
                                    <Dropdown
                                        placeholder="Start Time"
                                        options={this.standardHours}
                                        onChange={this.onStHourChange}
                                        completeMethod={this.getSelectedHour}
                                        optionLabel="shr1"
                                        value={this.state.selectedStHours}
                                    />
                                    <small style={{width: 200, paddingRight: '20px'}}>*Start Standard Time</small>

                                </div>

                                <div className="col-12 md:col-2">
                                    <Dropdown
                                        placeholder="End Time"
                                        options={this.standardHours}
                                        onChange={this.onStHourChange2}
                                        optionLabel="shr"
                                        value={this.state.selectedStHours2}
                                    />
                                    <small style={{width: 200, paddingRight: '20px'}}>*End Standard Time</small>

                                </div>

                                <div className="col-10 md:col-1">
                                    <Button label="Restart" onClick={this.clearFilters}/>
                                </div>

                                <div className="col-10 md:col-1">
                                    <Button label="Update"
                                            onClick={this.updateHoursV2}/>
                                </div>


                            </div>


                        </div>


                    </div>

                    <div className="card" style={{paddingTop:"0px"}}>
                        <DataTable ref={(el) => this.dt = el} value={this.state.rawReport}
                                    style={{width:'100%'}} dataKey="hora"
                                   showGridlines selectionMode="single"
                                   scrollable scrollDirection="both"
                                   tableClassName="slim-table"
                                   rowStyle={{height: '10px'}}
                                   >
                            <Column field="thr" header="Hours"
                                    style={{fontWeight: 'bold', fontSize: 12, paddingLeft: '15px', width: 87}} />
                            <Column body={this.actionSettingsOt} header="Sunday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 210}}/>
                            <Column body={this.actionSettings} header="Monday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 210}}/>
                            <Column body={this.actionSettings} header="Tuesday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 210}}/>
                            <Column body={this.actionSettings} header="Wednesday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 210}}/>
                            <Column body={this.actionSettings} header="Thursday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 210}}/>
                            <Column body={this.actionSettings} header="Friday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 201}}/>
                            <Column body={this.actionSettingsOt} header="Saturday" className="ta-center-block"
                                    style={{fontWeight: 'bold',fontSize: 11, paddingLeft: '35px', width: 202}}/>
                        </DataTable>


                    </div>

                </Card>
            </div>


        );
    }
}


export default Contacts;