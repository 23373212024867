import React, {Component} from 'react';
import WorkOder from "./WorkOder";
import ListWorkOrder from "./ListWorkOrder";

class WorkOrderHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected: 'ListTrackStore',
        }
        this.showBox = this.showBox.bind(this);
        this.moveAtMenuOption = this.moveAtMenuOption.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    moveAtMenuOption(optionMenu) {
        this.setState({
            opcionMenuSelected: optionMenu,
        });
    }

    render() {
        return (
            <div className="dashboard-banking">
                <div className="grid">
                    <div className="col-12 md:col-4 lg:col-4 overview-boxes" onClick={this.showBox('ListTrackStore')}
                         style={{cursor: 'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <i className="fa fa-road mt-5 ml-5"
                                   style={{'fontSize': '2.5em', color: "#FFFFFF"}}></i>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">List Work Order</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 overview-boxes" onClick={this.showBox('TrackStore')}
                         style={{cursor: 'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <i className="fa fa-pencil-square-o mt-6 ml-5"
                                   style={{'fontSize': '2.5em', color: "#FFFFFF"}}></i>
                            </div>
                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">New Work Order</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Page Content */}
                {(this.state.opcionMenuSelected === 'TrackStore') &&
                    <WorkOder moveAtMenuOption={this.moveAtMenuOption}/>}
                {(this.state.opcionMenuSelected === 'ListTrackStore') &&
                    <ListWorkOrder moveAtMenuOption={this.moveAtMenuOption}/>}
            </div>
        );
    }

}

export default WorkOrderHome;
