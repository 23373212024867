import React, {Component} from "react";
import Report from "./Report";
class TimesheetManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected: 'report',

        }
        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="dashboard-banking">
                <div className="grid">
                    <div className="col-12 md:col-4 lg:col-4 overview-boxes" onClick={this.showBox('report')}
                         style={{cursor: 'pointer'}}>

                    </div>

                </div>
                {(this.state.opcionMenuSelected === 'report') && <Report/>}

            </div>
        );
    }
}

export default TimesheetManagement;
