import React, {Component} from "react";
import {Toast} from "primereact/toast";
import {ProgressSpinner} from "primereact/progressspinner";
import {Card} from "primereact/card";
import GoogleMapReact from 'google-map-react';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {DateService} from "../../service/util/DateService";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {AutoComplete} from "primereact/autocomplete";
import {isEmpty} from "../utils/commonFunctions";

const MapMark = ({text, id}) => <div className="map-mark" id={"name_" + id} name={"name_" + id} data-tooltip={text}></div>;
export default class MapsPolar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location:[],
            GOOGLE_MAPS_KEY: "AIzaSyDwvpofRuIk2_azlQh3gV3FNvwTjV8EyjE",
            center: {
                lat: 19.434054,
                lng: -99.133106
            },
            zoom: 13,
            chargedYet: false,
            datesFilter: [],
            technicians: [],
            filteredTechnicians: null,
            technicianForm: '',
            selectedRegister: '',
            emailSelect: '',
            errors: {},
            processSuccess: true,
        }

        this.searchReportMaps = this.searchReportMaps.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.templateTechnician = this.templateTechnician.bind(this);
        this.searchTechnicians = this.searchTechnicians.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.loadTechnicians = this.loadTechnicians.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.dateService = new DateService();
    }
    componentDidMount() {
        this.setState({chargedYet: true});
        const technicians = JSON.parse(localStorage.getItem('technicians'));
        if (technicians == null) {
            this.loadTechnicians();
        } else {
            this.setState({ technicians: technicians, chargedYet: true });
        }
    }

    loadTechnicians(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            value: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });
            this.setState({
                technicians: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }


    searchReportMaps = () => {

        const paramsValidate = {
            date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
            date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
        };
        this.setState({processSuccess: true});
        const params = {
            "date_ini": paramsValidate.date_start,
            "date_end": paramsValidate.date_end,
            "username": (this.state.emailSelect !== '') ? this.state.emailSelect.value : null,
        };
        this.worxflowServices.getListUbication(params).then(resp => {
            const location = resp.data;
            const pos = {}
            if (location.length > 0) {
                pos.lat = location[0].latitude;
                pos.lng = location[0].longitude;
            }
            this.setState({location, center:pos});
            this.toast.show({severity:'success', summary: 'Success'});
            console.log(location);
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({
            [event.target.name]: event.target.value,
            errors
        });
        if(event.target.name === 'technicianForm'){
            this.setState({
                technicianForm: event.target.value,
                emailSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
    }
    validate = (data) => {
        let errors = {};
        if (data.date_start === null || data.date_end === null) {
            errors.datesFilter = 'Select a date range';
        }
        return errors;
    };


    templateTechnician(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }


    searchTechnicians(event) {
        setTimeout(() => {
            let filteredTechnicians;
            if (!event.query.trim().length) {
                filteredTechnicians = [...this.state.technicians];
            } else {
                filteredTechnicians = this.state.technicians.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTechnicians });
        }, 250);
    }

    clearFilters = () => {
        this.setState({
            datesFilter: [],
            technicianForm: '',
            selectedRegister: '',
        })
    }

    render() {
        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                                <div className="grid p-fluid">
                                    <div className="col-12 md:col-3">
                                        <Calendar id="range" value={this.state.datesFilter} name="datesFilter"
                                                  showIcon={true} showWeek={true} placeholder="Reporting Period: *"
                                                  onChange={this.changeHandler} selectionMode="range" readOnlyInput
                                                  className={this.state.errors?.datesFilter !== undefined ? "form-element p-invalid" : "form-element "}/>
                                        {this.state.errors?.datesFilter !== undefined ?
                                            <small className="offline">{this.state.errors?.datesFilter}</small>
                                            : ""
                                        }
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <AutoComplete
                                            className="form-element"
                                            placeholder="Select a technician"
                                            name="technicianForm"
                                            suggestions={this.state.filteredTechnicians}
                                            value={this.state.technicianForm}
                                            field="label" dropdown
                                            itemTemplate={this.templateTechnician}
                                            completeMethod={this.searchTechnicians}
                                            onChange={this.changeHandler}/>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Button label="Search" onClick={this.searchReportMaps}/>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Button icon="pi pi-times" onClick={this.clearFilters}
                                                tooltip="Clear filters" tooltipOptions={{position: 'top'}}/>
                                    </div>
                                </div>
                            <div className="col-12">
                                <div style={{height: '70vh', width: '100%'}}>
                                    {(this.state.location.length > 0) &&
                                        <GoogleMapReact
                                            bootstrapURLKeys={{key: this.state.GOOGLE_MAPS_KEY}}
                                            defaultCenter={this.state.center}
                                            defaultZoom={this.state.zoom}
                                        >
                                            {this.state.location.map((item, index) => {
                                                return (
                                                    <MapMark
                                                        lat={item.latitude}
                                                        lng={item.longitude}
                                                        text={item.email}
                                                    />
                                                );
                                            })}
                                        </GoogleMapReact>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
