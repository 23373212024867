import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
//import Card from "../shared/Card";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {UsuarioService} from "../../service/login/UsuarioService";
import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from 'primereact/toast';
import {Card} from "primereact/card";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: true,
            firstName: '',
            lastName: '',
            phone: '',

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
        };
    }

    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile !== undefined && profile !== null) {
            const dataProfile = profile.profile;
            this.setState({
                firstName: profile.first_name != null ? profile.first_name : '',
                lastName: profile.last_name != null ? profile.last_name : '',
                phone: dataProfile.phone_number != null ? dataProfile.phone_number : '',
            });
        }
    }

    submitHandler = e => {
        e.preventDefault();
        const self = this;
        const {
            firstName,
            lastName,
            phone,
        } = this.state;
        const profile = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
        };
        this.setState({chargedYet: false});
        const worxflowServices = new WorxflowServices();
        worxflowServices.updateProfile(profile).then(resp => {
            if (resp.status === 'success') {
                const usuarioService = new UsuarioService();
                usuarioService.getUsuarioLogueo().then(resp => {
                    this.setState({
                        firstName: resp.data.first_name,
                        lastName: resp.data.last_name,
                        phone: resp.data?.phone_number !== undefined ? resp.data?.phone_number : phone,
                    });
                    localStorage.setItem('profile', JSON.stringify(resp.data));
                }).catch(error => {
                    console.log(error);
                });
                self.growl.show({severity: 'success', summary: 'Sucess', detail: 'Profile saved'});
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            self.growl.show({severity: 'error', summary: 'Error', detail: 'Error save profile'});
            this.setState({chargedYet: true});
        });

    };

    changeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {
            firstName,
            lastName,
            phone,
        } = this.state;

        return (
            <Card>
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -20}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <div className="col-12">
                    <h1>Profile</h1>
                </div>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-4">
                        <h4>First Name</h4>
                        <InputText
                            placeholder="First Name"
                            name="firstName"
                            onChange={this.changeHandler}
                            value={firstName}
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <h4>Last Name</h4>
                        <InputText
                            placeholder="Last Name"
                            name="lastName"
                            onChange={this.changeHandler}
                            value={lastName}
                        />
                    </div>
                    <div className="col-12 md:col-4">
                        <h4>Phone</h4>
                        <InputText
                            placeholder="Phone"
                            name="phone"
                            onChange={this.changeHandler}
                            value={phone}
                        />
                    </div>
                    <div className="col-12 ta-center">
                        <div className="grid">
                            <div className="col-4 col-offset-4">
                                <Button label="Submit" style={{marginBottom: '10px'}} onClick={this.submitHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

}

export default Profile;
