import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import './layout-blue.css';
import {AuthService} from '../../service/login/AuthService';
import {UsuarioService} from '../../service/login/UsuarioService';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";

class Login extends Component {
    /**
     * Pagina de logueo al sistema
     * Heisenberg
     **/
    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
            login: false,
            errorLogin: null,
            messages: null,
            rand: Math.floor(Math.random() * (34 - 1)) + 1,
            height: window.innerHeight,
            usuario: this.datasource,
            visibleDialog: false,
            validando: false,
            redirectSignUp: false,
            urlLogo: '',
            urlHeader: '',
        }
        this.checkSession = this.checkSession.bind(this);
        this.authService = new AuthService();
        this.showError = this.showError.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.usuarioService = new UsuarioService();
        this.validarLogueo = this.validarLogueo.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.showMsg = this.showMsg.bind(this);
        this.onRedirectSignUp = this.onRedirectSignUp.bind(this);
        this.facebookLogin = this.facebookLogin.bind(this);
    }


    componentDidMount() {
        //console.log(window.location.href);
        //let queryString = window.location.search.slice(1);
        let url = new URL(window.location.href);
        let s = url.searchParams.get("s");

        if (s === "1") {
            this.showMsg("info", "", "Email was send you, please verify your account");
        }
        //if(this.props.location.query.s === 1){
        //    this.showMsg("info","", "Email was send you, please verify your account")
        //}
        if(localStorage.getItem('urlLogo') === null){
            this.worxflowServices = new WorxflowServices();
            this.worxflowServices.getAssets().then(resp => {
                let urlLogo;
                let urlHeader;
                resp.data.forEach(element => {
                    if(element.type === "Logo"){
                        urlLogo = process.env.REACT_APP_ENDPOINT_EXPRESS + element.url;
                    } else if(element.type === "Header"){
                        urlHeader = process.env.REACT_APP_ENDPOINT_EXPRESS + element.url;;
                    }
                });
                this.setState({urlLogo, urlHeader});
                localStorage.setItem('urlLogo', urlLogo);
                localStorage.setItem('urlHeader', urlHeader);
            }).catch(error => {
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({
                urlLogo: localStorage.getItem('urlLogo'),
                urlHeader: localStorage.getItem('urlHeader')
            });
        }
    }

    /**
     * Metodo de validacion de usuario y password no se encuentren vacios
     * params: usurname y password
     * return: true o false
     **/
    isValidForm() {
        let isValid = true;
        let usernameError = '';
        let passwordError = '';

        usernameError = this.state.username === '' ? 'El Usuario es obligatorio' : '';
        passwordError = this.state.password === '' ? 'La Contraseña es obligatorio' : '';

        isValid = usernameError === '' && passwordError === '';
        return isValid;
    }

    /**
     * Metodo para autenticacion del usuario
     * Se utiliza el serivicio AuthService
     * params: usurname y password
     * return: mensaje de error o acceso al sistema
     **/
    checkSession = (e) => {
        e.preventDefault();
        this.setState({validando: true})
        if (this.isValidForm()) {
            let errorLogin = true;
            this.setState({errorLogin});
            this.authService.loginService(this.state.username, this.state.password)
                .then(() => {
                    this.validarLogueo();
                }, (error) => {
                    this.showError();
                    this.setState({validando: false});
                });
        } else {
            this.setState({validando: false});
            this.showWarn();
        }
    }

    validarLogueo() {
        const self = this;
        self.usuarioService.getUsuarioLogueo().then(resp => {
            const profile = resp.data
            const role = profile.profile.rol;
            const userLogged = resp.data.first_name + ' ' + resp.data.last_name;
            self.setState({login: true});
            sessionStorage.setItem('userNameSesion', self.state.username);
            sessionStorage.setItem('role', role);
            sessionStorage.setItem('login', true);
            sessionStorage.setItem('userLogged', userLogged);
            localStorage.setItem('profile', JSON.stringify(profile));

            if (role === 'root' || role === 'User') {
                self.props.history.push("/work_order")
            } else if (role === 'sms') {
                self.props.history.push("/send_messages")
            } else if (role === 'time') {
                self.props.history.push("/timesheet")
            }


        }).catch(error => {
            console.log(error);
        });
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showError() {
        let msg = {severity: 'error', summary: 'ERROR', detail: 'Verify user and password are correct'};
        this.toast.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = {severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password'};
        this.toast.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg) {
        let msg = {severity: severityMsg, summary: summaryMsg, detail: detailMsg};
        this.toast.show(msg);
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.checkSession(e);
        }
    }

    onRedirectSignUp() {
        this.setState({redirectSignUp: true})
    }

    /**
     * @param response
     */
    facebookLogin(response) {
        console.log(response);
        this.authService.loginService(response.email, response.id)
            .then(() => {
                this.validarLogueo();
            }, (error) => {
                this.showError();
                this.setState({validando: false});
            });
    }



    render() {
        if (this.state.login) {
            return (<Redirect to={`/`}/>);
        }

        if (this.state.redirectSignUp) {
            return (<Redirect to={`/signup`}/>);
        }

        /*
        const divStylePadre = {
            overflow: 'hidden',
            margin: '0 auto',
            position: 'relative',
            height: '100%'
        }


        let imgUrl = '/assets/layout/images/fondo/' + this.state.rand + '.jpeg'
        //let imgUrl = '/assets/layout/images/fondo/1.jpeg'

        const divStyleHead = {
            //height: this.state.height-380,
            backgroundImage: `url(${imgUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'

        }

        const divStylePanel = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            marginBottom: '0px',
            borderRadius: '0px',
            bottom: '0px',
            textAlign: 'center',
            backgroundColor: '#292b2c'
        }

        const divStylePanelContent = {
            width: '400px',
            position: 'relative',
            marginLeft: '-200px',
            top: '10px',
            left: '50%',
            color: '#ffffff',
            padding: '10px'
        }

        const styleDialog = {
            color: '#4f8ac9',
            width: '40%'

        }

        const footer = (
            <div>
                <Button label="Confirmar" icon="pi pi-check" onClick={this.onChangePass}/>
                <Button label="Cancelar" icon="pi pi-times" onClick={this.onClose}/>
            </div>
        );

        let labelBtnIngresar = (
            <div>
                {(!this.state.validando) && <span>{etiquetasLogin.ingresar}</span>}
                {(this.state.validando) && <span><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                    {etiquetasLogin.ingresar}</span>}
            </div>
        );
        */

        return (
            <div className="login-body">
                <Toast ref={(el) => this.toast = el} />
                <div className="col-12 rightContainer">
                    <div className="col-12" style={{
                        position: 'relative',
                        height: '10%',
                        alignItems: 'center',
                        color: "white",
                        fontSize: '24px'
                    }}>
                        <h1 style={{
                            position: 'absolute',
                            top: '20%',
                            left: '25%',
                            marginRight: '5%',
                            fontSize: '20px',
                            color: "white"
                        }}>

                        </h1>
                    </div>
                    <div className="col-12" style={{textAlign: 'center'}}>
                        <div className="col-12">
                            <img alt="Logo" src="assets/layout/images/worxflowlogo-light.png" className="topbar-logo"/>
                        </div>
                    </div>
                </div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="grid">
                            <div className="col-3" style={{textAlign:'left'}}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                            </div>
                            <div className="col-9" style={{textAlign:'left'}}>
                                <h2 className="welcome-text">Welcome Guest</h2>
                                <span className="guest-sign-in">Sign in</span>
                            </div>
                            <div className="col-12" style={{textAlign:'left'}}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText placeholder="Username"
                                               id="usuario" onKeyPress={this._handleKeyPress}
                                               value={this.state.username}
                                               onChange={(e) => this.setState({username: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-12" style={{textAlign:'left'}}>
                                <label className="login-label">Password</label>
                                <div className="login-input">
                                    <InputText type="password" placeholder="Password"
                                               id="password" onKeyPress={this._handleKeyPress}
                                               value={this.state.password}
                                               onChange={(e) => this.setState({password: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="col-12 md:col-6 button-pane">
                                <Button label="Sign In"
                                        className={this.state.validando ? 'secondary-btn' : "p-button-primary"}
                                        disabled={this.state.validando} onClick={this.checkSession}
                                />
                            </div>
                            <div className="col-12 md:col-6 button-pane">
                                <Button label="Forgot password"
                                        className="p-button-secondary p-button-text link"
                                        onClick={(e) => {
                                            this.props.history.push("/forgot-password")
                                        }}>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
