import React, {Component} from "react";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {SelectButton} from "primereact/selectbutton";
import {isEmpty, rounded } from "../utils/commonFunctions";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import {Toolbar} from "primereact/toolbar";
import {InputText} from "primereact/inputtext";
import {BlockUI} from "primereact/blockui";

class Sheet extends Component {

    hours = [
        {hr: '00', hr1: '00', hr2: '00'}, {hr: '01', hr1: '01', hr2: '01'}, {hr: '02', hr1: '02', hr2: '02'},
        {hr: '03', hr1: '03', hr2: '03'}, {hr: '04', hr1: '04', hr2: '04'}, {hr: '05', hr1: '05', hr2: '05'},
        {hr: '06', hr1: '06', hr2: '06'}, {hr: '07', hr1: '07', hr2: '07'}, {hr: '08', hr1: '08', hr2: '08'},
        {hr: '09', hr1: '09', hr2: '09'}, {hr: '10', hr1: '10', hr2: '10'}, {hr: '11', hr1: '11', hr2: '11'},
        {hr: '12', hr1: '12', hr2: '12'}, {hr: '13', hr1: '13', hr2: '13'}, {hr: '14', hr1: '14', hr2: '14'},
        {hr: '15', hr1: '15', hr2: '15'}, {hr: '16', hr1: '16', hr2: '16'}, {hr: '17', hr1: '17', hr2: '17'},
        {hr: '18', hr1: '18', hr2: '18'}, {hr: '19', hr1: '19', hr2: '19'}, {hr: '20', hr1: '20', hr2: '20'},
        {hr: '21', hr1: '21', hr2: '21'}, {hr: '22', hr1: '22', hr2: '22'}, {hr: '23', hr1: '23', hr2: '23'}
    ];

    minutes = [
        {min: '00'}, {min: '01'}, {min: '02'}, {min: '03'}, {min: '04'}, {min: '05'}, {min: '06'}, {min: '07'}, {min: '08'}, {min: '09'},
        {min: '10'}, {min: '11'}, {min: '12'}, {min: '13'}, {min: '14'}, {min: '15'}, {min: '16'}, {min: '17'}, {min: '18'}, {min: '19'},
        {min: '20'}, {min: '21'}, {min: '22'}, {min: '23'}, {min: '24'}, {min: '25'}, {min: '26'}, {min: '27'}, {min: '28'}, {min: '29'},
        {min: '30'}, {min: '31'}, {min: '32'}, {min: '33'}, {min: '34'}, {min: '35'}, {min: '36'}, {min: '37'}, {min: '38'}, {min: '39'},
        {min: '40'}, {min: '41'}, {min: '42'}, {min: '43'}, {min: '44'}, {min: '45'}, {min: '46'}, {min: '47'}, {min: '48'}, {min: '49'},
        {min: '50'}, {min: '51'}, {min: '52'}, {min: '53'}, {min: '54'}, {min: '55'}, {min: '56'}, {min: '57'}, {min: '58'}, {min: '59'}
    ];

    schedule = [
        { sch: 'AM', boolean: true},
        { sch: 'PM', boolean: false},

    ];

    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            reports: [],
            customers: [],
            workOrder: '',
            workOrderSelect: '',
            filteredCustomer: null,
            filteredTechnicians: null,
            technicianForm: '',
            selectedHour:'',
            selectedHour2:'',
            selectedMinutes:'',
            selectedMinutes2:'',
            timeHour: [],
            timeHour2: [],
            timeMinutes: [],
            timeMinutes2: [],
            selectedSchedule: this.schedule[0],
            selectedSchedule2: this.schedule[1],
            blockedPanel: true,
            report: {
                id: null,
                profile: "",
                profile_desc: "",
                work_order: "",
                customer_data: "",
                date: "",
                after_hours: "",
                per_diem: 0,
                pto: 0,
                work_started: "",
                work_ended: "",
                technician: "",
                selected_hour_1: "",
                selected_hour_2: "",
                selected_minute_1: "",
                selected_minute_2: "",
                total_hours: "",
                total_hours_f: 0,
                work_st: "",
                work_ot: "",
                work_pto: "",
                work_diff: "",
                d_created: "",
                reason_id: '',
            },
            dataTableSelectValue: null,
            recipientActionType: null,
            currentLink: "",
            nextLink: null,
            previousLink: null,
            totalRow: 0,
            pageCurrent: 0,
            totalPage: 0,
            actionToConfirm: null,
            processingAction: false,
            displayConfirm: false,
            deleteSheetDialog: false,
            addUpdateDialog: false,
            errors: {},
            reasonsNoWO: [],
            globalFilter: null,
        }
        this.options = [
            {name: 'No', value: "N"},
            {name: 'Yes', value: "Y"}
        ];

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.callTimesheet = this.callTimesheet.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onHourChange2 = this.onHourChange2.bind(this);
        this.onMinutesChange = this.onMinutesChange.bind(this);
        this.onMinutesChange2 = this.onMinutesChange2.bind(this);
        this.onScheduleStartChange = this.onScheduleStartChange.bind(this);
        this.onScheduleStartChange2 = this.onScheduleStartChange2.bind(this);
        this.blockPanel = this.blockPanel.bind(this);
        this.unblockPanel = this.unblockPanel.bind(this);
        this.searchTechnicianWorkOrders = this.searchTechnicianWorkOrders.bind(this);
        this.changeHandlerReport = this.changeHandlerReport.bind(this);
        this.addUpdateDialog = this.addUpdateDialog.bind(this);
        this.deleteSheetDialog = this.deleteSheetDialog.bind(this);
        this.validate = this.validate.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.loadCatalogCustomers = this.loadCatalogCustomers.bind(this);
        this.templateCustomers = this.templateCustomers.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.searchWoO = this.searchWoO.bind(this);
        this.templateTechnician = this.templateTechnician.bind(this);
        this.loadTechnicians = this.loadTechnicians.bind(this);
        this.searchTechnicians = this.searchTechnicians.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.templateWorkOrder = this.templateWorkOrder.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.minDate = new Date();
        this.maxDate = new Date();
    }

    componentDidMount() {
        this.loadCatalogCustomers();
        this.searchWorkOrders();
        this.loadTechnicians();
        this.worxflowServices.getReasonList().then(resp => {
            let reasonsNoWO = [];
            resp.data.forEach(reason => {
                if(reason.status === "1"){
                    reason.label = reason.name;
                    reason.value = reason.id;
                    reasonsNoWO.push(reason);
                }
            });
            this.setState({reasonsNoWO});

        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    searchWorkOrders() {
            this.worxflowServices.getListWorkOrders().then(resp => {
                if (resp.status === "OK") {
                    console.log(resp.data)
                    const workOrder = resp.data
                    this.setState({workOrder})
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
    }

    loadCatalogCustomers() {
        const customers = JSON.parse(localStorage.getItem('customers'));
        if(customers == null){
            this.worxflowServices.getCustomerList().then(resp => {
                if (resp.status === "OK") {
                    resp.data.forEach(d => {
                        d.label = d.name;
                        d.value = d.id;
                    })
                    this.setState({
                        customers: customers,
                        chargedYet: true});
                    localStorage.setItem('customers', JSON.stringify(resp.data));
                    this.callTimesheet();
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
        } else {
            this.setState({customers});
            this.callTimesheet();
        }
    }

    loadTechnicians(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            value: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });
            this.setState({
                technicians: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    searchTechnicians(event) {
        setTimeout(() => {
            let filteredTechnicians;
            if (!event.query.trim().length) {
                filteredTechnicians = [...this.state.technicians];
            } else {
                filteredTechnicians = this.state.technicians.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            console.log(filteredTechnicians)
            this.setState({ filteredTechnicians });
        }, 250);
    }



    calculateMinMaxDateCalendar() {
        let today = new Date();

        switch (today.getDay()) {
            case 0:
                this.minDate.setDate(today.getDate() - 3);
                this.maxDate.setDate(today.getDate() + 4);
                break;
            case 1:
                this.minDate.setDate(today.getDate() - 4);
                this.maxDate.setDate(today.getDate() + 3);
                break;
            case 2:
                this.minDate.setDate(today.getDate() - 5);
                this.maxDate.setDate(today.getDate() + 2);
                break;
            case 3:
                this.minDate.setDate(today.getDate() - 6);
                this.maxDate.setDate(today.getDate() + 1);
                break;
            case 4:
                this.minDate.setDate(today.getDate() - 0);
                this.maxDate.setDate(today.getDate() + 7);
                break;
            case 5:
                this.minDate.setDate(today.getDate() - 1);
                this.maxDate.setDate(today.getDate() + 6);
                break;
            case 6:
                this.minDate.setDate(today.getDate() - 2);
                this.maxDate.setDate(today.getDate() + 5);
                break;
            default:
                break;
        }


        this.minDate.setDate(today.getDate() - 365);
        this.maxDate.setDate(today.getDate() + 365);
    }
    callTimesheet(currentLink) {
        currentLink = currentLink === undefined ? "" : currentLink;
        this.worxflowServices.getTimesheetMobile(null, currentLink).then(resp => {
            let totalPage = resp.total / resp.page_size;
            totalPage = Math.trunc(totalPage);
            if(resp.total % resp.page_size > 0){
                totalPage += 1;
            }
            resp.results.forEach(element => {
                element.total_hours_f = rounded(element.total_hours_f)
                const reason = this.state.reasonsNoWO.find(e => e.id === element.reason_id);
                element.reason_desc = reason !== undefined ? reason.name : "";
            })
            this.setState({
                reports: resp.results,
                chargedYet: true,
                nextLink: resp.links.next,
                previousLink: resp.links.previous,
                totalRow: resp.total,
                pageCurrent: resp.page,
                totalPage,
                currentLink
            });
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }

    previousPage(){
        let tmp = this.state.previousLink.split('?');
        const currentLink = "?" + tmp[1];
        this.callTimesheet(currentLink);
    }

    nextPage(){
        let tmp = this.state.nextLink.split('?');
        const currentLink = "?" + tmp[1];
        this.callTimesheet(currentLink);
    }

    showModal = (modal, type) => () => {
        this.loadTechnicians();
        this.blockPanel();
        const {report, dataTableSelectValue} = this.state;
        this.calculateMinMaxDateCalendar();

        if (type === "new") {
            report.id = null;
            report.work_order = "";
            report.customer = "";
            report.customer_data = this.state.technicianForm;
            report.technician = "";
            report.date = "";
            report.after_hours = "N";
            report.per_diem = 0;
            report.pto = 0;
            report.selected_hour_1 = this.state.selectedHour;
            report.selected_hour_2 = this.state.selectedHour2;
            report.selected_minute_1 = this.state.selectedMinutes;
            report.selected_minute_2 = this.state.selectedMinutes2;
            report.total_hours = "";
            report.total_hours_f = "";
            report.work_started = "";
            report.work_ended = "";
            report.d_created = "";
            report.reason_id = "";
        } else {
            if (dataTableSelectValue === null) {
                this.toast.show({severity: 'error', summary: 'Error', detail: "No data selected."});
                return;
            }
            if(dataTableSelectValue.after_hours === "Y" || dataTableSelectValue.after_hours === "N"){
                report.after_hours = dataTableSelectValue.after_hours;
            } else {
                report.after_hours = "N"
            }

            report.id = dataTableSelectValue.id;
            report.work_order = dataTableSelectValue.work_order;
            report.total_hours_f = dataTableSelectValue.total_hours_f;
            report.d_created = dataTableSelectValue.d_created;
            report.date = new Date(dataTableSelectValue.date);
            report.per_diem = parseInt(dataTableSelectValue.per_diem);
            report.pto = parseInt(dataTableSelectValue.pto);
            report.per_diem = isNaN(report.per_diem) ? 0 : report.per_diem;
            report.pto = isNaN(report.pto) ? 0 : report.pto;
            report.customer = dataTableSelectValue.customer;
            report.work_order = {
                name: dataTableSelectValue.work_order
            }
            this.state.customers.forEach(customer => {
                if(customer.name === dataTableSelectValue.customer){
                    report.customer = customer.name;
                    report.customer_id = customer.id;
                } else {
                    report.customer = dataTableSelectValue.customer;
                    report.customer_id = dataTableSelectValue.id;
                }
            })
            report.reason_id = dataTableSelectValue.reason_id;
        }

        if (type === "update"){
            try {
                report.work_order = {
                    name: dataTableSelectValue.work_order
                }
                let hours = 0;
                let minutes = 0;
                if(dataTableSelectValue.work_started.split(":").length >= 2){
                    let dateStarted = new Date();
                    hours = dataTableSelectValue.work_started.split(":")[0];
                    minutes = dataTableSelectValue.work_started.split(":")[1];
                    dateStarted.setHours(parseInt(hours));
                    dateStarted.setMinutes(parseInt(minutes));
                    report.work_started = dateStarted;
                }
                if(dataTableSelectValue.work_ended.split(":").length >= 2){
                    let dateEnded = new Date();
                    hours = dataTableSelectValue.work_ended.split(":")[0];
                    minutes = dataTableSelectValue.work_ended.split(":")[1];
                    dateEnded.setHours(parseInt(hours));
                    dateEnded.setMinutes(parseInt(minutes));
                    report.work_ended = dateEnded;
                }
                if(dataTableSelectValue.total_hours.split(":").length >= 2){
                    let dateTotalHours = new Date();
                    hours = dataTableSelectValue.total_hours.split(":")[0];
                    minutes = dataTableSelectValue.total_hours.split(":")[1];
                    dateTotalHours.setHours(parseInt(hours));
                    dateTotalHours.setMinutes(parseInt(minutes));
                    report.total_hours = dateTotalHours;
                }
            } catch (error){
                console.log("error: " + error)
            }
        }
        if(modal === "deleteSheetDialog") {
            report.date = dataTableSelectValue.date;
            report.work_started = dataTableSelectValue.work_started;
            report.work_ended = dataTableSelectValue.work_ended;
            report.total_hours = dataTableSelectValue.total_hours;
        }

        report.work_st = null;
        report.work_ot = null;
        report.work_pto = null;
        report.work_diff = null;

        this.setState({
            [modal]: true,
            recipientActionType: type
        });
    }

    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            report: {
                ...this.state.report,
                work_order: "",
                customer: "",
                customer_id: undefined,
                reason_id: "",
                date: "",
                technicianForm: ""
            },
            displayConfirm: false,
            dataTableSelectValue: false,
            technicianForm: null,
            workOrderSelect: null,
            selectedHour: null,
            selectedHour2: null,
            timeHour: null,
            timeHour2: null,
            timeMinutes: null,
            timeMinutes2: null,
            selectedMinutes: null,
            selectedMinutes2: null

        });
        this.blockPanel();
        console.log("entra")
    }



    confirmationDialog = (nameModal, showModal, action) => {
        let sltHour;
        let sltHour2;
        let sltminutes;
        let sltminutes2;

        if (this.state.selectedHour.hr !== undefined) {
            sltHour = this.state.selectedHour.hr
            this.state.timeHour.push(sltHour);
            this.setState({timeHour: sltHour});
            console.log(this.state.timeHour)
        } else {
            sltHour = this.state.selectedHour
            if (this.state.selectedHour.length < 2) {
                sltHour = '0' + sltHour
                console.log(sltHour)
            }
            if (sltHour > "24") {
                sltHour = ""
                console.log(sltHour)
            }
            this.state.timeHour.push(sltHour);
            this.setState({timeHour: sltHour});
            console.log(this.state.timeHour)
        }

        if (this.state.selectedMinutes.min !== undefined) {
            sltminutes = this.state.selectedMinutes.min
            this.state.timeMinutes.push(sltminutes);
            this.setState({timeMinutes: sltminutes});
            console.log(this.state.timeMinutes)
        } else {
            sltminutes = this.state.selectedMinutes
            if (this.state.selectedMinutes.length < 2) {
                sltminutes = '0' + sltminutes
                console.log(sltminutes)
            }
            if (sltminutes > "59") {
                sltminutes = ""
                console.log(sltminutes)
            }
            this.state.timeMinutes.push(sltminutes);
            this.setState({timeMinutes: sltminutes});
            console.log(this.state.timeMinutes)
        }

        if (this.state.selectedHour2.hr !== undefined) {
            sltHour2 = this.state.selectedHour2.hr
            this.state.timeHour2.push(sltHour2);
            this.setState({timeHour2: sltHour2});
            console.log(this.state.timeHour2)
        } else {
            sltHour2 = this.state.selectedHour2
            if (this.state.selectedHour2.length < 2) {
                sltHour2 = '0' + sltHour2
                console.log(sltHour2)
            }
            if (sltHour2 > "24") {
                sltHour2 = ""
                console.log(sltHour2)
            }
            this.state.timeHour2.push(sltHour2);
            this.setState({timeHour2: sltHour2});
            console.log(this.state.timeHour2)
        }

        if (this.state.selectedMinutes2.min !== undefined) {
            sltminutes2 = this.state.selectedMinutes2.min
            this.state.timeMinutes2.push(sltminutes2);
            this.setState({timeMinutes2: sltminutes2});
            console.log(this.state.timeMinutes2)
        } else {
            sltminutes2 = this.state.selectedMinutes2
            if (this.state.selectedMinutes2.length < 2) {
                sltminutes2 = '0' + sltminutes2
                console.log(sltminutes2)
            }
            if (sltminutes2 > "59") {
                sltminutes2 = ""
                console.log(sltminutes2)
            }
            this.state.timeMinutes2.push(sltminutes2);
            this.setState({timeMinutes2: sltminutes2});
            console.log(this.state.timeMinutes2)
        }

       /* if (this.state.selectedSchedule.sch === 'PM') {
            sltHour = parseInt(sltHour, 10) + 12
            sltHour = sltHour.toString()
            this.state.timeHour.push(sltHour);
            this.state.timeHour.reverse();
            this.setState({timeHour: sltHour});
            console.log(this.state.timeHour)
        }

        if (this.state.selectedSchedule2.sch === 'PM') {
            sltHour2 = parseInt(sltHour2, 10) + 12
            sltHour2 = sltHour2.toString()
            this.state.timeHour2.push(sltHour2);
            this.state.timeHour2.reverse();
            this.setState({timeHour2: sltHour2});
            console.log(this.state.timeHour2)
        } */

        const params = {
            work_order: this.state.report.work_order,
            customer: this.state.report.customer,
            date: this.state.report.date,
            work_started: this.state.timeHour[0] + ":" + this.state.timeMinutes[0],
            work_ended: this.state.timeHour2[0] + ":"  + this.state.timeMinutes2[0],
            reason_id: this.state.report.reason_id,
            username: this.state.report.technicianForm.value
        };

        const errors = this.validate(params);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        } else {
            this.setState({errors});
        }
    }


    validate = (data) => {
        let errors = {};

        if (!data.work_order && !data.reason_id) {
            errors.reason_id = 'Reason is mandatory.';
        }
        if (!data.customer) {
            errors.customer = 'Customer is mandatory.';
        }
        if (!data.date) {
            errors.date = 'Date is mandatory.';
        }
        if (!data.work_started) {
            errors.work_started = 'Work started is mandatory.';
        }
        if (this.state.timeHour[0] === "" || isNaN(this.state.timeHour[0])) {
            errors.work_started = 'Incorrect work started.';
        }
        if (this.state.timeMinutes[0] === "" || isNaN(this.state.timeMinutes[0])) {
            errors.work_started = 'Incorrect work started.';
        }
        if (!data.work_ended) {
            errors.work_ended = 'Work ended is mandatory.';
        }
        if (this.state.timeHour2[0] === "" || isNaN(this.state.timeHour2[0])) {
            errors.work_ended = 'Incorrect work ended.';
        }
        if (this.state.timeMinutes2[0] === "" || isNaN(this.state.timeMinutes2[0])) {
            errors.work_ended = 'Incorrect work ended.';
        }

        return errors;
    }

    onHourChange= event => {
        this.setState({
            selectedHour: event.value,
            timeHour: [],
            selectedMinutes: '00',
            timeMinutes: []
        });

    }

    onHourChange2= event => {
        this.setState({
            selectedHour2: event.value,
            timeHour2: [],
            selectedMinutes2: '00',
            timeMinutes2: []
        });

    }

    onMinutesChange= event => {
        this.setState({
            selectedMinutes: event.value,
            timeMinutes: []
        });
    }


    onMinutesChange2= event => {
        this.setState({
            selectedMinutes2: event.value,
            timeMinutes2: []
        });
    }

    onScheduleStartChange= event => {
            this.setState({
                selectedSchedule: event.value
            });


    }

    onScheduleStartChange2= event => {
        this.setState({
            selectedSchedule2: event.value
        });
    }

    blockPanel() {
        this.setState({
            blockedPanel: true
        });
    }

    unblockPanel() {
        this.setState({
            blockedPanel: false
        });
    }

    searchTechnicianWorkOrders() {
        const param = {
            username: this.state.report.technicianForm.value
        };
        if (this.state.report.technicianForm.value === undefined) {
            this.setState({
                report: {
                    ...this.state.report,
                    work_order: "",
                    customer: "",
                    customer_id: undefined,
                    reason_id: "",
                    date: ""
                },
                workOrderSelect: null,
                selectedHour: null,
                selectedHour2: null,
                timeHour: null,
                timeHour2: null,
                timeMinutes: null,
                timeMinutes2: null,
                selectedMinutes: null,
                selectedMinutes2: null

            });
            this.blockPanel();
        }
        console.log(this.state.report.work_order)
            this.worxflowServices.getTechnicianWorkOrders(param).then(resp => {
                if (resp.status === "OK") {
                    console.log(resp.data)
                    const workOrder = resp.data
                    this.setState({workOrder})
                    this.unblockPanel();
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });

    }

    changeHandlerReport = event => {
        const {errors, report} = this.state;
        if(event.target.name === 'reason_id') {
            errors['work_order'] = undefined;
        }

        if(event.target.name === 'technicianForm'){
            this.setState({
                technicianForm: event.target.value,
                emailSelect: event.target.value
            });

        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }


        if (event.target !== undefined && event.target.name !== undefined) {
            if (errors[event.target.name] !== undefined) {
                errors[event.target.name] = undefined;
            }
            report[event.target.name] = event.target.value;
        } else {
            if (errors[event.originalEvent.target.name] !== undefined) {
                errors[event.originalEvent.target.name] = undefined;
            }
            report[event.originalEvent.target.name] = event.originalEvent.target.value;
        }
        this.setState({report, errors});
        this.searchTechnicianWorkOrders();
    }

    changeHandlerWorkOrder = event => {

        if (event.value.name !== undefined) {
            const select_customer = event.value.partner_id
            const customer_id = event.value.partner_id[0]
            const customer_name = event.value.partner_id[1]
            const customer_d = {
                id: customer_id,
                name: customer_name,
                label: customer_name
            }
            console.log(customer_d)
            this.setState({
                report: {
                    ...this.state.report,
                    customer: customer_d.label,
                    customer_id: customer_d.id,
                    work_order: event.value
                }})
            this.setState({customer_d, select_customer})
        } else {
            this.setState({
                report: {
                    ...this.state.report,
                    work_order: event.value
                }})
        }

        console.info(this.state.report.customer_data)
    }


    addUpdateDialog(){
        console.log("addUpdateDialog")
        const {report, recipientActionType} = this.state;
        const content = JSON.parse(JSON.stringify(report));
        const strSeconds = ":00";
        const sHour1 = this.state.timeHour + ":";
        const sHour2 = this.state.timeHour2 + ":";
        let date = ("0" + (report.date.getDate())).slice(-2);
        let month = ("0" + (report.date.getMonth() + 1)).slice(-2);
        let year = report.date.getFullYear();
        content.date = year + "-" + month + "-" + date;
        content.customer = report.customer;
        content.customer_id = report.customer_id;
        content.work_order = report.work_order;
        if(report.work_started === ""){
            content.work_started = this.state.timeHour + ":" + this.state.timeMinutes+ strSeconds;
        }

        if(report.work_ended === ""){
            content.work_ended = this.state.timeHour2 + ":" + this.state.timeMinutes2 + strSeconds;
        }

        if(!isEmpty(report.total_hours)){
            content.total_hours = parseInt(sHour1, 10) + parseInt(sHour2, 10) + strSeconds;
        }
        const params = {
            work_order: content.work_order.name !== undefined ? content.work_order.name : content.work_order,
            customer: content.customer,
            customer_id: content.customer_id,
            reason_id: content.reason_id === "" ? null: content.reason_id,
            date: content.date,
            after_hours: content.after_hours,
            per_diem: content.per_diem,
            pto: content.pto,
            work_started: content.work_started,
            work_ended: content.work_ended,
            total_hours: content.total_hours,
            work_st: content.work_st,
            work_ot: content.work_ot,
            work_pto: content.work_pto,
            work_diff: content.work_diff,
            username: content.technicianForm.value
        }
        if(recipientActionType === "update"){
            params.id = content.id;
            this.worxflowServices.updateTimesheet(params).then(resp => {
                if (resp.status === 200) {
                    this.toast.show({severity: 'success', summary: 'Success', detail: "Updated."});
                    this.closeConfirmModal();
                    this.callTimesheet();
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
        } else {
            const paramsAdd = {
                work_order: content.work_order.name !== undefined ? content.work_order.name : content.work_order,
                customer: this.state.report.customer.name !== undefined ? this.state.report.customer.name : this.state.report.customer,
                customer_id: this.state.report.customer_id !== undefined ? this.state.report.customer_id : this.state.report.customer.id,
                reason_id: content.reason_id === "" ? null: content.reason_id,
                date: content.date,
                after_hours: content.after_hours,
                per_diem: content.per_diem,
                pto: content.pto,
                work_started: content.work_started,
                work_ended: content.work_ended,
                total_hours: content.total_hours,
                work_st: content.work_st,
                work_ot: content.work_ot,
                work_pto: content.work_pto,
                work_diff: content.work_diff,
                username: content.technicianForm.value
            }
            this.worxflowServices.addTimesheet(paramsAdd).then(resp => {
                if (resp.status === 200) {
                    this.toast.show({severity: 'success', summary: 'Success', detail: "Saved."});
                    this.closeConfirmModal();
                    this.callTimesheet();
                }
            }).catch(error => {
                if (error.response?.data["msg-warning"] !== '') {
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: error.response?.data["msg-warning"]
                    });
                }
                console.error("Error on service: ", error);
            });
        }
    }

    deleteSheetDialog() {
        const {report} = this.state;
        const params = {
            id: report.id
        }
        this.worxflowServices.deleteTimesheet(params).then(resp => {
            if (resp.status === 200) {
                this.toast.show({severity: 'success', summary: 'Success', detail: "Deleted."});
                this.closeConfirmModal();
                this.callTimesheet();
            }
        }).catch(error => {
            console.error("Error on service: ", error);
        });
    }
    closeConfirmModal(){
        this.setState({
            report: {
                ...this.state.report,
                work_order: "",
                customer: "",
                customer_id: undefined,
                reason_id: "",
                date: "",
                technicianForm: ""
            },
            displayConfirm: false,
            addUpdateDialog: false,
            dataTableSelectValue: null,
            technicianForm: null,
            deleteSheetDialog: false,
            processingAction: false,
            selectedHour: null,
            selectedHour2: null,
            timeHour: null,
            timeHour2: null,
            timeMinutes: null,
            timeMinutes2: null,
            selectedMinutes: null,
            selectedMinutes2: null
        });
    }

    templateCustomers(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }

    templateTechnician(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }

    templateWorkOrder(item) {
        return (
            <div className="country-item">
                <div id={item.name}>{item.name}</div>
            </div>
        );
    }

    rightToolbarTemplate() {

        if (this.state.reports.length > 0) {
            return (
                <React.Fragment>
                    <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="p-button-raised pi pi-search"/>
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Search..."/>
                </span>
                    </div>
                </React.Fragment>
            )}
    }
    exportCSV() {
        this.dt.exportCSV();
    }

    searchWoO(event) {
        setTimeout(() => {
            let filteredWoO;
            if (!event.query.trim().length) {
                filteredWoO = [...this.state.workOrder];
            } else {
                filteredWoO = this.state.workOrder.filter((workOrder) => {
                    return workOrder.name.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredWoO });
        }, 250);
    }

    searchCustomers(event) {
            this.setState({
                report: {
                    ...this.state.report,

                }, workOrderSelect: null})

        if (this.state.workOrderSelect !== null){
            this.setState({
                report: {
                    ...this.state.report,
                    customer_data: null
                }, })
        }

        if (this.state.customer_data !== null){
            this.setState({
                report: {
                    ...this.state.report,
                }, workOrderSelect: null })
        }


        setTimeout(() => {
            let filteredCustomer;
            if (!event.query.trim().length) {
                filteredCustomer = [...this.state.customers];
            } else {
                filteredCustomer = this.state.customers.filter((customer) => {
                    return customer && typeof customer.label === 'string' && customer.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCustomer });
        }, 250);
    }

    render() {

        const renderFooterConfirm = (nameModal, action) => (
            <div >
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processingAction}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processingAction}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true} style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>

                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)}  label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" className={this.state.deleteSheetDialog ? "p-button-danger" : ""}
                        onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );



        const addUpdateDialog = (

            <Dialog header={this.state.recipientActionType === 'new' ? "Add New Timesheet" : "Update Details"}
                    visible={this.state.addUpdateDialog} modal
                    style={{width: '850px'}} contentStyle={{height: 465}}
                    footer={dialogFooter('addUpdateDialog', this.addUpdateDialog,
                        this.state.recipientActionType === 'new' ? 'Add' : 'Update')}
                    onHide={this.hideModal('addUpdateDialog')}>
                <Card height="height-1000">
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Technicians: </b>
                        <AutoComplete
                            className="form-element"
                            placeholder="Select a technician"
                            name="technicianForm"
                            suggestions={this.state.filteredTechnicians}
                            value={this.state.report.technicianForm}
                            field="label" dropdown
                            itemTemplate={this.templateTechnician}
                            completeMethod={this.searchTechnicians}
                            onChange={this.changeHandlerReport}/>
                        {this.state.errors?.reason_id !== undefined ?
                            <small className="offline">{this.state.errors?.reason_id}</small>
                            : ""
                        }

                    </div>
                </Card>

                <div className="grid p-fluid" style={{paddingTop: '15px'}}>
                    <BlockUI blocked={this.state.blockedPanel}>
                        <div className="grid p-fluid" style={{paddingTop: '5px'}}>
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>WorkOrder: </b>
                                <AutoComplete
                                    placeholder="Work order"
                                    name="work_order" dropdown
                                    itemTemplate={this.templateWorkOrder}
                                    suggestions={this.state.filteredWoO}
                                    completeMethod={this.searchWoO}
                                    onChange={this.changeHandlerWorkOrder}
                                    field="name"
                                    value={this.state.report.work_order}/>

                                {this.state.errors?.workOrder !== undefined ?
                                    <small className="offline">{this.state.errors?.workOrder}</small>
                                    : ""
                                }
                            </div>

                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>Customer: </b>
                                <AutoComplete
                                    className="form-element"
                                    placeholder="Customer"
                                    name="customer"
                                    suggestions={this.state.filteredCustomer}
                                    value={this.state.report.customer}
                                    field="label" dropdown
                                    itemTemplate={this.templateCustomers}
                                    completeMethod={this.searchCustomers}
                                    onChange={this.changeHandlerReport}/>
                                {this.state.errors?.customer !== undefined ?
                                    <small className="offline">{this.state.errors?.customer}</small>
                                    : ""
                                }

                            </div>


                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>Date: </b>
                                <Calendar id="calendar" value={this.state.report.date}
                                          name="date" dateFormat="yy/mm/dd"
                                          minDate={this.minDate} maxDate={this.maxDate}
                                          disabled={this.state.recipientActionType !== 'new'}
                                          onChange={this.changeHandlerReport}
                                          className={this.state.errors?.date !== undefined ? "form-element p-invalid" : "form-element"}/>
                                {this.state.errors?.date !== undefined ?
                                    <small className="offline">{this.state.errors?.date}</small>
                                    : ""
                                }
                            </div>
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>After hours: </b>
                                <SelectButton value={this.state.report.after_hours}
                                              name="after_hours"
                                              options={this.options}
                                              optionLabel="name"
                                              onChange={this.changeHandlerReport}
                                              className="button-blue"
                                />
                            </div>
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>Per diem: </b>
                                <InputNumber value={this.state.report.per_diem}
                                             name="per_diem"
                                             onValueChange={(e) => this.setState({
                                                 report: {
                                                     ...this.state.report,
                                                     per_diem: e.value
                                                 }
                                             })}
                                />
                            </div>
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>PTO: </b>
                                <InputNumber value={this.state.report.pto}
                                             name="pto"
                                             onValueChange={(e) => this.setState({
                                                 report: {
                                                     ...this.state.report,
                                                     pto: e.value
                                                 }
                                             })}
                                />
                            </div>
                            <div className="col-12 md:col-2" style={{width: '110px'}}>
                                <b>Work started: </b>
                                <Dropdown
                                    className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                                    value={this.state.selectedHour}
                                    placeholder="Hour"
                                    name="work_started"
                                    onChange={this.onHourChange}
                                    options={this.hours}
                                    optionLabel="hr1" editable
                                    input type="number"/>
                                {this.state.errors?.work_started !== undefined ?
                                    <small className="offline">{this.state.errors?.work_started}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-field p-grid mt-12" style={{paddingTop: '15px', fontSize: '20px'}}>
                                <b>:</b>
                            </div>
                            <div className="col-12 md:col-2" style={{paddingTop: '24px'}}>
                                <Dropdown
                                    className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                                    value={this.state.selectedMinutes}
                                    placeholder="Minutes"
                                    name="work_started"
                                    onChange={this.onMinutesChange}
                                    options={this.minutes}
                                    optionLabel="min" editable/>
                            </div>
                            {/*<div className="col-12 md:col-2" style={{paddingTop: '24px', width: '95px'}}>
                        <Dropdown
                            className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                            value={this.state.selectedSchedule}
                            placeholder="AM"
                            name="work_started"
                            onChange={this.onScheduleStartChange}
                            options={this.schedule}
                            optionLabel="sch"/>

                    </div>*/}

                            <div className="col-12 md:col-4" style={{paddingLeft: '170px', width: '278px'}}>
                                <b>Work ended: </b>
                                <Dropdown
                                    className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                                    value={this.state.selectedHour2}
                                    placeholder="Hour"
                                    name="work_ended"
                                    onChange={this.onHourChange2}
                                    options={this.hours}
                                    optionLabel="hr2" editable/>
                                {this.state.errors?.work_ended !== undefined ?
                                    <small className="offline">{this.state.errors?.work_ended}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-field p-grid mt-12" style={{paddingTop: '15px', fontSize: '20px'}}>
                                <b>:</b>
                            </div>
                            <div className="col-12 md:col-2" style={{paddingTop: '24px'}}>
                                <Dropdown
                                    className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                                    value={this.state.selectedMinutes2}
                                    placeholder="Minutes"
                                    name="work_ended"
                                    onChange={this.onMinutesChange2}
                                    options={this.minutes}
                                    optionLabel="min" editable/>
                            </div>
                            {/*<div className="col-12 md:col-2" style={{paddingTop: '24px', width: '95px'}}>
                        <Dropdown
                            className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                            value={this.state.selectedSchedule2}
                            placeholder="AM"
                            name="work_ended"
                            onChange={this.onScheduleStartChange2}
                            options={this.schedule}
                            optionLabel="sch"/>
                    </div>*/}
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>Reason: </b>
                                <Dropdown
                                    className={this.state.errors?.reason_id !== undefined ? "form-element p-invalid" : "form-element"}
                                    placeholder="Reasons"
                                    name="reason_id"
                                    value={this.state.report.reason_id}
                                    options={this.state.reasonsNoWO}
                                    showClear
                                    onChange={this.changeHandlerReport}/>
                                {this.state.errors?.reason_id !== undefined ?
                                    <small className="offline">{this.state.errors?.reason_id}</small>
                                    : ""
                                }
                            </div>
                        </div>
                    </BlockUI>
                </div>
            </Dialog>
    );


    const deleteDialog = (
        <Dialog header="Delete timesheet" visible={this.state.deleteSheetDialog} modal
                    style={{width: '650px'}} contentStyle={{height: 200}}
                    footer={dialogFooter('deleteSheetDialog', this.deleteSheetDialog, 'Confirm Delete')}
                    onHide={this.hideModal('deleteSheetDialog')}>
                <div className="datail-transaction">
                    <div className="grid">
                        <div className="col-4"><b>profile_desc: </b>{this.state.report.profile}</div>
                        <div className="col-4"><b>Work order: </b>{this.state.report.work_order}</div>
                        <div className="col-4"><b>Customer: </b>{this.state.report.customer_data}</div>
                        <div className="col-4"><b>Date: </b>{this.state.report.date}</div>
                        <div className="col-4"><b>After hours: </b>{this.state.report.after_hours}</div>
                        <div className="col-4"><b>Per diem: </b>{this.state.report.per_diem}</div>
                        <div className="col-4"><b>PTO: </b>{this.state.report.pto}</div>
                        <div className="col-4"><b>Work started: </b>{this.state.report.work_started}</div>
                        <div className="col-4"><b>Work ended: </b>{this.state.report.work_ended}</div>
                        <div className="col-4"><b>Total hours: </b>{this.state.report.total_hours}</div>
                        <div className="col-4"><b>Hours F: </b>{this.state.report.total_hours_f}</div>
                        <div className="col-4"><b>Created: </b>{this.state.report.d_created}</div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="headButtons">
                                <Button label="Add" onClick={this.showModal('addUpdateDialog', 'new')} />
                                <Button label="Update" onClick={this.showModal('addUpdateDialog', 'update')} className="p-button-info"/>
                                <Button label="Delete" onClick={this.showModal('deleteSheetDialog')} className="p-button-danger"/>
                                <div style={{float: "right"}}>
                                    <b style={{marginRight: '15px', fontSize: '1rem', color: '#007bff'}}>{`Total rows: ${this.state.totalRow}`}</b>
                                    <b style={{marginRight: '15px', fontSize: '1rem', color: '#007bff'}}>{`Page ${this.state.pageCurrent} of ${this.state.totalPage}`}</b>
                                    <Button label="Previous" onClick={this.previousPage}
                                            icon="pi pi-angle-left" disabled={this.state.previousLink === null ? true : false}
                                            className="p-button-secondary p-button-raised p-button-rounded" />
                                    <Button label="Next" onClick={this.nextPage}
                                            icon="pi pi-angle-right" iconPos="right" disabled={this.state.nextLink === null ? true : false}
                                            className="p-button-secondary p-button-raised p-button-rounded"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 m-t-20">
                            <Toolbar className="p-mb-4" style={{backgroundColor: 'white', paddingTop: '0px',paddingBottom: '0px', paddingLeft: '0px' }} left={this.rightToolbarTemplate}></Toolbar>
                        </div>
                        <div className="col-12">
                            <DataTable ref={(el) => this.dt = el} value={this.state.reports} selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) =>
                                           this.setState({dataTableSelectValue: e.value})}
                                       selectionMode="single" dataKey="id" showGridlines
                                       scrollable scrollHeight="550px" loading={!this.state.chargedYet} scrollDirection="both"
                                       globalFilter={this.state.globalFilter}>
                                <Column field="date" header="Date" className="ta-center" sortable style={{fontSize: 13, width: 115}}/>
                                <Column field="work_order" header="Order" className="ta-right" sortable style={{fontSize: 13, width: 270}}/>
                                <Column field="customer" header="Customer" className="ta-left" sortable style={{fontSize: 13, width: 290}}/>
                                <Column field="after_hours" header="After hours" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="per_diem" header="Diem" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="pto" header="PTO" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="work_started" header="Started" className="ta-center" sortable style={{fontSize: 13, width: 110}}/>
                                <Column field="work_ended" header="Ended" className="ta-center" sortable style={{fontSize: 13, width: 110}}/>
                                <Column field="total_hours_f" header="Hours" className="ta-center" sortable style={{fontSize: 13, width: 85}}/>
                                <Column field="reason_desc" sortable header="Reason" className="ta-center" style={{fontSize: 13, width: 115}}/>
                                <Column field="d_created" header="Created" className="ta-center" sortable style={{fontSize: 13, width: 170}}/>
                            </DataTable>
                        </div>
                    </div>
                </Card>
                {confirmDialog}
                {addUpdateDialog}
                {deleteDialog}
            </div>
        )
    }
}

export default Sheet;
