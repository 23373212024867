import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppInlineProfile} from './AppInlineProfile';
import {AppMenu} from './AppMenu';
import {Route, Redirect} from 'react-router-dom';
import {withRouter} from 'react-router'
import {UsuarioService} from "./service/login/UsuarioService";
import {AuthService} from './service/login/AuthService';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
import 'font-awesome/css/font-awesome.css';
import './App.css';
import 'react-phone-input-2/lib/style.css';
import Profile from "./components/profile";
import Technicians from "./components/Technicians";
import ResetPassword from "./components/ResetPassword";
import SendMessages from "./components/SendMessages";
import Settings from "./components/Settings";
import reasons from "./components/Reasons";
import permissions from "./components/Permissions";
import Timesheet from "./components/Timesheet";
import TimesheetManagement from "./components/TimesheetManagement";
import TimesheetAdmin from "./components/TimesheetAdmin";
import TimesheetV2 from "./components/TimesheetV2";
import WorkOrderHome from "./components/WorkOrder";
import Polar from "./components/polar";
import Ruta from "./components/ruta";
import TimeProcess from "./components/TimesheetsProcess";
/*css for use full calendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
*/
class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            profileMode: 'top',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: true,
            menuActive: false,
            theme: 'blue',
            layout: 'blue',
            version: 'v4',
            configDialogActive: false,

            logout: false,
            usuarioSesion: null,
            login: false,
            gridObjeto: {},
            mostrarBandejaEnFooter: false,
            configObjecto: {},
            recibidosTopbar: 0,
            atendidosTopbar: 0,
            activeIndexBandeja: 0,
            urlLogo: "/assets/layout/images/worxflowlogo-light.png",
            urlHeader: "/assets/layout/images/worxflowlogo-light.png",
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeProfileMode = this.changeProfileMode.bind(this);
        this.changeVersion = this.changeVersion.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.onHandleClickProfile = this.onHandleClickProfile.bind(this);
        this.onHandleClickResetFile = this.onHandleClickResetFile.bind(this);
        this.validarSesion = this.validarSesion.bind(this);
        this.createMenu = this.createMenu.bind(this);
        this.actualizarTotalTopbar = this.actualizarTotalTopbar.bind(this);
        this.actionToAccount = this.actionToAccount.bind(this);

        this.authService = new AuthService();
        this.usuarioService = new UsuarioService();
    }

    componentDidMount() {
        let role = sessionStorage.getItem('role');

        this.setState({role: role});
        this.createMenu();

        const second = 60000;
        const minutes = 0.3 * second;
        setInterval(() => {
            const current_time = Date.now() / 1000;
            const expiration = Number.parseInt(sessionStorage.getItem("expires_date"));
            if (expiration < current_time) {
                this.onLogout();
            }
        }, minutes);

    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        } else {
            if (this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onConfigButtonClick(event) {
        this.configClick = true;
        this.setState({configDialogActive: !this.state.configDialogActive})
    }

    onConfigCloseClick() {
        this.setState({configDialogActive: false})
    }

    onConfigClick() {
        this.configClick = true;
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.configClick) {
            this.setState({configDialogActive: false});
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.configClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({layoutMode: event.layoutMode})
        if (event.layoutMode === 'horizontal') {
            this.setState({profileMode: 'top'})
        }
    }

    changeMenuColor(event) {
        this.setState({darkMenu: event.darkMenu})
    }

    changeProfileMode(event) {
        this.setState({profileMode: event.profileMode})
    }

    changeVersion(event) {
        this.setState({version: event.version});
        if (event.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('layout-css', this.state.layout + '-v4', 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme + '-v4', 'theme');
        }
    }

    changeLayout(event) {
        this.setState({layout: event.layout});
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', event.layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', event.layout + '-v4', 'layout');
        }

        if (event.special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeTheme(event) {
        this.setState({theme: event.theme});
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', event.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', event.theme + '-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        this.replaceLink(element, newURL);
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    /**
     * Metodo para obtner el usuario de la sesion y validarlo
     * params:
     * return: usuarioSesion
     **/
    validarSesion() {
        let usuarioSesion = JSON.parse(sessionStorage.getItem('usuarioSesion'));
        let isLogin = JSON.parse(sessionStorage.getItem('login'));
        if (isLogin) {
            this.setState({login: true});
        } else {
            this.setState({login: false});
        }
        if (usuarioSesion) {
            this.setState({usuarioSesion});
            //console.log("usuarioSesion.idUsuario:",usuarioSesion.idUsuario);
            return usuarioSesion.idUsuario;
        } else {
            return '';
        }
    }

    onLogout() {
        sessionStorage.clear();
        localStorage.clear();
        let logout = true;
        if (this !== undefined) {
            if (this.handle) {
                clearInterval(this.handle);
            }
            this.setState({
                logout
            });
        }
    }

    onHandleClickProfile = (e) => {
        this.props.history.push("/profile");
    }
    onHandleClickResetFile = (e) => {
        this.props.history.push("/restPassword");
    }

    createMenu() {
        const self = this;
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile !== undefined && profile !== null) {
            let role = profile.profile.rol;
            this.setState({login: true});
            sessionStorage.setItem('userNameSesion', self.state.username);
            sessionStorage.setItem('role', role);
            self.menu = [];

            /*if (role === 'root' || role === 'User') {
                self.menu.push({label: 'Work Orders', icon: 'fa fa-road', to: '/work_order'});
            }*/
            if (role === 'root' || role === 'sms') {
                self.menu.push({label: 'Send messages', icon: 'fa fa-envelope', to: '/send_messages'});
            }
            if (role === 'root' || role === 'admin' ) {
                self.menu.push({label: 'Settings', icon: 'fa fa-cog', to: '/settings'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin') {
                self.menu.push({label: 'Reasons', icon: 'fa fa-list-ol', to: '/reasons'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin' ) {
                self.menu.push({label: 'Permissions', icon: 'fa fa-key', to: '/permissions'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin') {
                self.menu.push({label: 'Technicians', icon: 'fa fa-cogs', to: '/technicians'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin') {
                self.menu.push({label: 'Process Timesheets', icon: 'fa fa-cogs', to: '/timeProcess'});
            }
            if (role === 'time' ) {
                self.menu.push({label: 'Timesheet', icon: 'fa fa-calendar', to: '/timesheet'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin') {
                self.menu.push({label: 'Timesheet', icon: 'fa fa-calendar', to: '/timesheet_v2'});
            }
            if (role === 'root' || role === 'admin' || role === 'time_admin' ) {
                self.menu.push({label: 'Timesheet Management', icon: 'fa fa-calendar', to: '/timesheet_management'});
            }
            if (role === 'root' || role === 'admin' ) {
                self.menu.push({label: 'Timesheet Admin', icon: 'fa fa-calendar', to: '/timesheet_admin'});
            }
            if (role === 'root' || role === 'time_admin') {
                self.menu.push({label: 'Polar', icon: 'fa fa-map-o', to: '/polar'});
            }
            if (role === 'root' || role === 'ruta') {
                self.menu.push({label: 'Ruta', icon: 'fa fa-calendar', to: '/ruta'});
            }
            self.menu.push({label: 'Logout ', icon: 'fa fa-sign-out', to: '/login', command: () => self.onLogout()});
        }
    }

    actionToAccount() {
        console.log("Em App actionToAccount")
        this.setState({opcionMenuSelected: 'Account'});
    }

    /**
     * Metodo para pasar el total de operaciones al topbar
     */
    actualizarTotalTopbar(recibidosTopbar, atendidosTopbar) {
        this.setState({
            recibidosTopbar,
            atendidosTopbar
        });
    }

    render() {
        if (JSON.parse(sessionStorage.getItem('login') == null)) {
            return (<Redirect to={`/login`}/>)
        }

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                               topbarMenuActive={this.state.topbarMenuActive}
                               activeTopbarItem={this.state.activeTopbarItem}
                               onMenuButtonClick={this.onMenuButtonClick}
                               onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                               onTopbarItemClick={this.onTopbarItemClick} onLogout={this.onLogout}
                               profile={{'userName': sessionStorage.getItem("userLogged"), 'area': ''}}
                               onHandleClickProfile={this.onHandleClickProfile}
                               onHandleClickResetFile={this.onHandleClickResetFile}
                    />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <div className="menu-scroll-content">
                            {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') &&
                                <AppInlineProfile onLogout={this.onLogout} profile={{
                                    'userName': sessionStorage.getItem("userLogged"),
                                    'area': ''
                                }}
                                                  onHandleClickProfile={this.onHandleClickProfile}/>}
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick}
                                     onRootMenuItemClick={this.onRootMenuItemClick}
                                     layoutMode={this.state.layoutMode} active={this.state.menuActive}/>
                        </div>
                    </div>

                    <div className="layout-main">
                        <Route path='/work_order' component={WorkOrderHome}/>
                        <Route path='/profile' component={Profile}/>
                        <Route path='/restPassword' component={ResetPassword}/>
                        <Route path='/send_messages' component={SendMessages}/>
                        <Route path='/settings' component={Settings}/>

                        <Route path='/reasons' component={reasons}/>
                        <Route path='/permissions' component={permissions}/>
                        <Route path='/technicians' component={Technicians}/>
                        <Route path='/timesheet' component={Timesheet}/>
                        <Route path='/timesheet_v2' component={TimesheetV2}/>
                        <Route path='/timesheet_management' component={TimesheetManagement}/>
                        <Route path='/timesheet_admin' component={TimesheetAdmin}/>

                        <Route path='/polar' component={Polar}/>
                        <Route path='/ruta' component={Ruta}/>
                        <Route path='/timeProcess' component={TimeProcess}/>
                    </div>

                    <div className="layout-mask"></div>
                </div>
            </div>
        );
    }
}

export default withRouter(App);
