import React, {Component} from 'react';
import {Toast} from 'primereact/toast';
import {Button} from "primereact/button";
import './confirmTrackForm.css';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Message} from "primereact/message";
import {Dialog} from "primereact/dialog";

export default class ConfirmTrackForm extends Component {
    constructor() {
        super();
        this.state = {
            serviceWorking:false,
            user_id: null,
            track_id: null,
            token: null,
            dataTrack: null,
            is_check:false,
            messagesCompleted: 'The ticket has already been checked.',
            displayConfirm: false,
        }
        this.worxflowServices = new WorxflowServices();
        this.submitHandler = this.submitHandler.bind(this);
        this.onHide = this.onHide.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
    }

    componentDidMount() {
        const user_id=this.props.match.params['user_id'];
        const track_id=this.props.match.params['track_id'];
        const token=this.props.match.params['token'];
        this.setState({user_id, track_id, token});
        this.getTrackStoreData(user_id, track_id, token);
    }

    submitHandler = () => {
        this.setState({is_check: true});
        const {user_id, track_id, token} = this.state;
        const params = {
            user_id: user_id,
            track_id: track_id,
            token: token
        };
        this.worxflowServices.sendCheckTrackstore(params).then(resp => {
            if (resp["status_code"] === 200) {
                this.toast.show({severity: 'success', summary: 'Track successful', detail: resp["message"], life: 10000});
                this.getTrackStoreData(user_id, track_id, token);
            }
        }).catch(error => {
            this.setState({is_check: false, displayConfirm: false});
            console.error("Error en el servicio: ", error);
        });
    }

    getTrackStoreData(user_id, track_id, token) {
        this.worxflowServices.getTrackstore(user_id, track_id, token).then(resp => {
            if (resp["status code"] === 200) {
                this.setState({
                    dataTrack: resp.data,
                    is_check: resp.data.is_check,
                    displayConfirm: false
                })
            }
        }).catch(error => {
            console.error("Error en el servicio: ", error);
            this.setState({is_check: true});
        });
    }

    onHide() {
        this.setState({displayConfirm: false});
    }
    showConfirm(){
        this.setState({displayConfirm: true});
    }

    renderFooter() {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.onHide()} className="p-button-secondary" />
                <Button label="Yes" icon="pi pi-check" onClick={() => this.submitHandler()} autoFocus />
            </div>
        );
    }

    render() {
        const {
            serviceWorking,
            dataTrack,
            is_check,
            messagesCompleted,
            displayConfirm
        } = this.state;

        return (
            <div className="layout-main other_text">
                <Toast ref={(el) => this.toast = el} />
                <div className="card track-panel p-fluid">
                    <span className="blockquote-header">Polar Refrigeration Inc.</span>
                    {is_check &&
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Message severity="success" text={messagesCompleted} />
                        </div>
                    </div>
                    }
                    <div className="p-datatable-wrapper">
                        <table className="table striped">
                            <thead><tr>
                                <td>Origin</td>
                                <td>Data</td>
                            </tr></thead>
                            <tbody>
                            <tr>
                                <td>Store Name:</td>
                                <td className="textBold">{dataTrack?.store_desc?.name}</td>
                            </tr>
                            <tr>
                                <td>From:</td>
                                <td className="textBold">{dataTrack?.name_call}</td>
                            </tr>
                            <tr>
                                <td>Contact Phone:</td>
                                <td className="textBold">{dataTrack?.phone_number}</td>
                            </tr>

                            <tr>
                                <td>Problem Description:</td>
                                <td className="textBold">{dataTrack?.problem_description}</td>
                            </tr>

                            <tr>
                                <td>Is Emergengy:</td>
                                <td className="textBold">{dataTrack?.is_emergency?.toString().toUpperCase()}</td>
                            </tr>

                            <tr>
                                <td>Part Affected:</td>
                                <td className="textBold">{dataTrack?.part_affected}</td>
                            </tr>

                            <tr>
                                <td>Type Frozen Equipment:</td>
                                <td className="textBold">
                                    {dataTrack?.type_frozen_desc !== null ? dataTrack?.type_frozen_desc?.name: ''}
                                </td>
                            </tr>

                            <tr>
                                <td>Product Pulled:</td>
                                <td className="textBold">{dataTrack?.product_pulled?.toString().toUpperCase()}</td>
                            </tr>

                            <tr>
                                <td>Authorization Code:</td>
                                <td className="textBold"></td>
                            </tr>

                            <tr>
                                <td>PO Number:</td>
                                <td className="textBold"></td>
                            </tr>

                            <tr>
                                <td>NTE:</td>
                                <td className="textBold">{dataTrack?.nte}</td>
                            </tr>

                            <tr>
                                <td>Are there any alarm codes?:</td>
                                <td className="textBold">{dataTrack?.alarm_code?.toString().toUpperCase()}</td>
                            </tr>


                            <tr>
                                <td>Store Number:</td>
                                <td className="textBold">{dataTrack?.store_number}</td>
                            </tr>

                            <tr>
                                <td>Cutomer Type:</td>
                                <td className="textBold">{dataTrack?.store_desc?.customer_type_desc}</td>
                            </tr>

                            <tr>
                                <td>Store Address:</td>
                                <td className="textBold">
                                    {dataTrack?.store_desc?.address?.length > 0 ?
                                        dataTrack?.store_desc?.address :
                                        dataTrack?.store_desc?.address_1 !== undefined ? (dataTrack?.store_desc?.address_1 + " " + dataTrack?.store_desc?.address_2) :
                                    ''}
                                </td>
                            </tr>

                            <tr>
                                <td>Store Phone:</td>
                                <td className="textBold">{dataTrack?.store_desc?.phone}</td>
                            </tr>
                            <tr>
                                <td>Store Email:</td>
                                <td className="textBold">{dataTrack?.store_desc?.email !== null ? dataTrack?.store_desc?.email : 'None'}</td>
                            </tr>
                            <tr>
                                <td>Contact Name:</td>
                                <td className="textBold">{dataTrack?.store_desc?.name}</td>
                            </tr>
                            <tr>
                                <td>Create At:</td>
                                <td className="textBold">{dataTrack?.d_create}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{marginLeft: 225}} className="mb-20">
                        <div style={{width:200}}>
                            <Button label="Confirm" onClick={this.showConfirm} disabled={is_check || serviceWorking}/>
                        </div>
                    </div>
                    <span className="blockquote-footer">Please review ticket on https://polar.live.worxflow.net/login</span>
                </div>
                <Dialog header="Confirm" visible={displayConfirm}
                        footer={this.renderFooter()} onHide={() => this.onHide()}>
                    <p>Are you sure to confirm the track?</p>
                </Dialog>
            </div>
        );
    }
}
