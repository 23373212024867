import React, {Component} from 'react';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Dialog} from "primereact/dialog";
import {Card} from "primereact/card";

class ListWorkOrder extends Component {
    constructor() {
        super();
        this.state = {
            chargedYet: false,
            reports:[],
            workOrders: [],
            dataTableSelectValue: {
                'store_desc':'short_name',
                'type_equipment_desc':'short_name',
                'type_frozen_desc':'short_name',
            },
        }
        this.callApiReporthistory = this.callApiReporthistory.bind(this);
        this.actionBodyDetail = this.actionBodyDetail.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.moveAtMenuOption = this.moveAtMenuOption.bind(this);
        this.worxflowServices = new WorxflowServices();
    }

    componentDidMount() {
        this.callApiReporthistory();
    }

    actionBodyDetail() {
        this.setState({
            detailDialog: true,
        });
    }

    hideDialog() {
        this.setState({
            detailDialog: false,
        });
    }

    callApiReporthistory() {
        this.worxflowServices.getListTrackStore().then(resp => {
            if(resp.data != null && Array.isArray(resp.data)){
                resp.data.forEach((element) => {
                    element.d_created = element.d_created.substring(0,10);
                    if(element.d_check !== null && element.d_check.length > 10){
                        element.d_check_h  = element.d_check.substring(11);
                        element.d_check = element.d_check.substring(0,10);
                    } else {
                        element.d_check_h= '';
                    }
                });
            }
            const workOrders = resp.data;
            this.setState({
                workOrders,
                chargedYet: true
            });

        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    changeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    moveAtMenuOption(){
        this.props.moveAtMenuOption('TrackStore');
    }

    render() {

        const footer = (
            <React.Fragment>
                <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={this.hideDialog} />
            </React.Fragment>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>

                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="headButtons">
                                <Button label='Search' onClick={this.callApiReporthistory}/>
                                <Button label="Detail" onClick={this.actionBodyDetail}/>
                                <Button label="New order" icon="pi pi-directions" className="p-button-info"
                                        onClick={this.moveAtMenuOption} />
                            </div>
                            <div className="col-12">
                                <DataTable value={this.state.workOrders} selection={this.state.dataTableSelectValue}
                                           onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}
                                           selectionMode="single" className="smallContentDataTable"
                                           reorderableColumns={true} paginator={true} rows={50}
                                           resizableColumns={true} dataKey="id">
                                    <Column selectionMode="single" headerStyle={{width: 50}} className="ta-center"></Column>
                                    <Column field="store_desc.short_name" header="Store" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="store_desc.short_number" header="Number" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="name_call" header="Name" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="phone_number" header="Phone" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="vnet_number" header="Vnet" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="nte" header="NTE" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="type_equipment_desc.name" header="Equipment" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="part_affected" header="Part" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="is_emergency" header="Emergency" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="user" header="User" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="d_check" header="Checked date" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="d_check_h" header="Time" className="ta-right" sortable style={{fontSize: 13}}/>
                                    <Column field="d_created" header="Created" className="ta-right" sortable style={{fontSize: 13}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </Card>
                <Dialog visible={this.state.detailDialog} style={{width: '720px'}}
                        header="Detail Worker" modal className="p-fluid" footer={footer}
                        onHide={this.hideDialog}>
                    <div className="datail-transaction">
                        <div className="grid">
                            <div className="col-12 ta-center p-20">
                                <table className="table striped no-min ta-left">
                                    <thead><tr></tr></thead>
                                    <tbody>
                                    <tr><td>Store</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.store_desc.short_name}</td>
                                        <td>Store Number</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.store_desc.short_number}</td></tr>
                                    <tr><td>Nte</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.nte}</td>
                                        <td>Vnet</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.vnet_number}</td></tr>
                                    <tr><td>Name</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.name_call}</td>
                                        <td>Part Affected</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.part_affected}</td></tr>
                                    <tr><td>Phone</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.phone_number}</td>
                                        <td>Equipment</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.type_equipment_desc.name}</td></tr>
                                    <tr><td>Frozen</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.type_frozen_desc.name}</td>
                                        <td>Product Pulled</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.product_pulled}</td></tr>
                                    <tr><td>User</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.user}</td>
                                        <td>Is Emergency</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.is_emergency}</td></tr>
                                    <tr><td>Check</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.is_check?.toString().toUpperCase()}</td>
                                        <td>Date Check</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.d_check}</td></tr>
                                    <tr><td>Email</td>
                                        <td className="textBold">{this.state.dataTableSelectValue.email}</td>
                                        <td></td><td className="textBold"></td></tr>
                                    <tr>
                                        <td>Description Problem</td>
                                        <td colSpan={3} className="textBold">{this.state.dataTableSelectValue.problem_description}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }

}

export default ListWorkOrder;
