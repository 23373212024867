import React, {Component} from "react";
import {Toast} from "primereact/toast";
import {ProgressSpinner} from "primereact/progressspinner";
import {Card} from "primereact/card";
import GoogleMapReact from 'google-map-react';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {RutaServices} from "../../service/ruta/RutaServices";
import {DateService} from "../../service/util/DateService";
import {Button} from "primereact/button";
import {isEmpty} from "../utils/commonFunctions";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";

const MapMark = ({text, id}) => <div className="map-mark" id={"name_" + id} name={"name_" + id} data-tooltip={text}>{text}</div>;
export default class MapsRuta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location:[],
            //GOOGLE_MAPS_KEY: "AIzaSyD4XbBPcXZxwShRNullGlk6kVKyIpFp1Qk",
            GOOGLE_MAPS_KEY: "AIzaSyDwvpofRuIk2_azlQh3gV3FNvwTjV8EyjE",
            center: {
                lat: 19.434054,
                lng: -99.133106
            },
            zoom: 13,
            chargedYet: false,
            datesFilter: [],
            technicians: [],
            filteredTechnicians: null,
            technicianForm: '',
            selectedRegister: '',
            inicioForm: '',
            finForm: '',
            resultData: '',
            emailSelect: '',
            errors: {},
            processSuccess: true,
        }

        this.searchReportMaps = this.searchReportMaps.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.templateTechnician = this.templateTechnician.bind(this);
        this.searchTechnicians = this.searchTechnicians.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.loadTechnicians = this.loadTechnicians.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.rutaServices = new RutaServices();
        this.dateService = new DateService();
    }
    componentDidMount() {
        this.setState({chargedYet: true});
        const technicians = JSON.parse(localStorage.getItem('technicians'));
        if (technicians == null) {
            this.loadTechnicians();
        } else {
            this.setState({ technicians: technicians, chargedYet: true });
        }
    }

    loadTechnicians(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            value: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });
            this.setState({
                technicians: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }


    searchReportMaps = () => {

        this.setState({processSuccess: true});
        const params = {
            "station_ini": this.state.inicioForm,
            "station_end": this.state.finForm,
        };
        this.rutaServices.postFindGrafo(params).then(resp => {
            const location = resp.data.markers;
            const pos = {}
            if (location.length > 0) {
                pos.lat = location[0].point.lat;
                pos.lng = location[0].point.lng;
            }
            this.setState({location, center:pos});
            this.setState({resultData:JSON.stringify(location)});
            this.toast.show({severity:'success', summary: 'Success'});
            console.log(location);
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({
            [event.target.name]: event.target.value,
            errors
        });
        if(event.target.name === 'technicianForm'){
            this.setState({
                technicianForm: event.target.value,
                emailSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
    }
    validate = (data) => {
        let errors = {};
        if (data.date_start === null || data.date_end === null) {
            errors.datesFilter = 'Select a date range';
        }
        return errors;
    };


    templateTechnician(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }


    searchTechnicians(event) {
        setTimeout(() => {
            let filteredTechnicians;
            if (!event.query.trim().length) {
                filteredTechnicians = [...this.state.technicians];
            } else {
                filteredTechnicians = this.state.technicians.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTechnicians });
        }, 250);
    }

    clearFilters = () => {
        this.setState({
            datesFilter: [],
            technicianForm: '',
            selectedRegister: '',
        })
    }

    render() {
        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                                <div className="grid p-fluid">
                                    <div className="col-12 md:col-3">
                                        <InputText
                                            placeholder="Punto Inicial"
                                            name="inicioForm"
                                            onChange={this.changeHandler}
                                            value={this.state.inicioForm}
                                        />

                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputText
                                            placeholder="Punto Final"
                                            name="finForm"
                                            onChange={this.changeHandler}
                                            value={this.state.finForm}
                                        />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Button label="Search" onClick={this.searchReportMaps}/>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <Button icon="pi pi-times" onClick={this.clearFilters}
                                                tooltip="Clear filters" tooltipOptions={{position: 'top'}}/>
                                    </div>
                                </div>
                            <div className="col-12">
                                <div style={{height: '70vh', width: '100%'}}>
                                    {(this.state.location.length > 0) &&
                                        <GoogleMapReact
                                            bootstrapURLKeys={{key: this.state.GOOGLE_MAPS_KEY}}
                                            defaultCenter={this.state.center}
                                            defaultZoom={this.state.zoom}
                                        >
                                            {this.state.location.map((item, index) => {
                                                return (
                                                    <MapMark
                                                        lat={item.point.lat}
                                                        lng={item.point.lng}
                                                        text={item.title}

                                                    />
                                                );
                                            })}
                                        </GoogleMapReact>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <div style={{height: '70vh', width: '100%'}}>
                                    <InputTextarea
                                        name="resultForm"
                                        value={this.state.resultData}
                                        pt={{
                                            root: {
                                                rows: 40,
                                                cols: 100
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
