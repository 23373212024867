import React, {Component} from 'react';
import Contacts from "./Contacts";

class Reasons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opcionMenuSelected: 'Contacts',
        }
        this.showBox = this.showBox.bind(this);
    }

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="dashboard-banking">
                <div className="grid">
                    <div className="col-12 md:col-4 lg:col-4 overview-boxes" onClick={this.showBox('Contacts')}
                         style={{cursor: 'pointer'}}>
                        <div className="overview clearfix">
                            <div className="overview-icon">
                                <i className="fa fa-bars mt-5 ml-5"
                                   style={{'fontSize': '2.5em', color: "#FFFFFF"}}></i>
                            </div>

                            <div className="overview-text">
                                <span className="overview-title"></span>
                                <span className="overview-subtitle">Reasons</span>
                            </div>
                        </div>
                    </div>
                </div>

                {(this.state.opcionMenuSelected === 'Contacts') && <Contacts />}
            </div>
        );
    }
}

export default Reasons;
