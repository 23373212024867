import React, {Component} from 'react';
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Dropdown} from "primereact/dropdown";
import {SelectButton} from "primereact/selectbutton";
import {Calendar} from "primereact/calendar";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {InputMask} from "primereact/inputmask";
import {Dialog} from "primereact/dialog";
import {AutoComplete} from "primereact/autocomplete";
import {validateEmail} from "../utils/commonFunctions";
import {Card} from "primereact/card";

class WorkOder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            stores: [],
            storeNameId: '',
            list_store_numbers: [],
            filteredStores: null,
            store_numbers: [],
            storeNumberId: '',
            selectedStore: {},
            vnet_number: '',
            authorization_code: '',
            firstName: '',
            lastName: '',
            NTE: '',
            name_call: '',
            phone_number: '',
            email: '',
            type_equipment: '',
            problem_description: '',
            partOption: [],
            part_affected: '',
            is_emergency: false,
            emergencyOption: [{label: 'Yes', value: true}, {label: 'No', value: false}],
            frozen: '',
            frozenOption: [],
            product_pulled: false,
            alarm_code: false,
            description_alarm_code: '',
            hours_operation_start: '',
            hours_operation_end: '',
            errors: {},
            processSuccess: true,

            displayConfirm: false,
            actionToConfirm: null,
            processService: false,

            editStoreNumberDialog: false,
            editStoreNameDialog: false,
            partAffectedDialog:false,
            frozenDialog:false,

            storeNameForm: '',
            partAffectedForm: '',
            frozenForm: '',
            address1Form: '',
            address2Form: '',
            cityForm: '',
            stateForm: '',
            nameForm: '',
            leadSourceForm: '',
            phoneForm: '',
            zipForm: '',
            type_equipment_id_Form: '',
            type_frozen_id_Form: '',
            short_numberForm: '',
            emailForm: '',
            firstNameForm: '',
            lastNameForm: '',
        }
        this.worxflowServices = new WorxflowServices();

        this.searchObjectSelected = this.searchObjectSelected.bind();
        this.checkStoreName = this.checkStoreName.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.submitStoreNameHandler = this.submitStoreNameHandler.bind(this);
        this.submitStoreNumberHandler = this.submitStoreNumberHandler.bind(this);
        this.submitTypeEquipment = this.submitTypeEquipment.bind(this);
        this.submitFrozenHandler = this.submitFrozenHandler.bind(this);
        this.templateStores = this.templateStores.bind(this);
        this.searchStore = this.searchStore.bind(this);
    }

    componentDidMount() {
        const localStores = JSON.parse(localStorage.getItem('localStores'));
        if (localStores == null) {
            this.loadStoreParent();
        } else {
            this.setState({
                stores: localStores,
                chargedYet: true,
                processSuccess:false
            });
        }

        this.loadListFrozen();
        this.loadListTypeEquipment();
        this.submitHandler();
    }
    loadStoreParent(){
        this.worxflowServices.getStoreParent().then(resp => {
            let stores = [];
            const map = new Map();
            resp.forEach((element, index) => {
                if(!map.has(element.name)) {
                    map.set(element.name, true);
                    stores.push({label: element.name, value: element.id});
                }
            });
            this.setState({
                stores,
                chargedYet: true,
                processSuccess:false
            });
            localStorage.setItem('localStores', JSON.stringify(stores));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    loadListFrozen(){
        this.worxflowServices.getListFrozen().then(resp => {
            const frozenOption = [];
            resp.forEach(element => {
                const data = {label: element.name, value: element.id};
                frozenOption.push(data);
            });
            this.setState({frozenOption});
        }).catch(error => {
            console.error("Error en el servicio /frozen/list/: ", error);
        });
    }
    loadListTypeEquipment(){
        this.worxflowServices.getListTypeEquipment().then(resp => {
            const partOption = [];
            resp.forEach(element => {
                const data = {label: element.name, value: element.id};
                partOption.push(data);
            });
            this.setState({partOption});
        }).catch(error => {
            console.error("Error en el servicio /equipment/list/: ", error);
        });
    }
    changeHandler = event => {
        const {errors} = this.state;

        if(event.target.name === 'email' && !validateEmail(event.target.value)){
            errors[event.target.name] = 'Email is not valid.';
        } else if(errors[event.target.name] !== undefined){
            errors[event.target.name] = undefined;
        }

        if(event.target.name === 'storeNameId' || event.target.name === 'storeNameForm'){
            this.setState({
                storeNameForm: event.target.value,
                storeNameId: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
    }
    searchObjectSelected = event => {
        const {
            errors,
        } = this.state;
        if(event.value?.value > 0){
            this.worxflowServices.getStoreChild(event.value.value).then(resp => {
                if(resp.status === "success"){
                    const numbers = [];
                    const map = new Map();
                    resp.data.forEach(element => {
                        if(element['status_desc'] === 'Active') {
                            if(!map.has(element['short_number'])) {
                                map.set(element['short_number'], element);
                                numbers.push({label: element['short_number'], value: element.id});
                            } else {
                                map.set(element['short_number']+element.id, element);
                                numbers.push({label: element['short_number'] + ' - ' + +element.id, value: element.id});
                            }
                        }
                    });
                    this.setState({
                        list_store_numbers: resp.data,
                        store_numbers: numbers,
                        chargedYet: true,
                        processSuccess: false
                    });
                }
            }).catch(error => {
                console.error("Error en el servicio getChild: ", error);
            });
        }

        errors.storeNameId = undefined;
        this.setState({errors});
    }
    checkStoreName = event => {
        const {
            storeNameId
        } = this.state;
        if(storeNameId?.value === undefined) {
            this.setState({
                list_store_numbers: [],
                store_numbers: [],
                chargedYet: true,
                processSuccess: false
            });
        }
    }
    searchChildSelected = event => {
        const {
            list_store_numbers,
            errors,
        } = this.state;
        const store = list_store_numbers.find(data => data.id === event.target.value);
        errors.store_number = undefined;
        this.setState({
            selectedStore: store,
            [event.target.name]: event.target.value,
            errors
        });
    }
    submitHandler = () => {
        const params = {
            store_id: this.state.selectedStore.id,
            store_number: this.state.selectedStore.short_number,
            vnet_number: this.state.vnet_number,
            nte: this.state.NTE,
            name_call: this.state.firstName + ' ' + this.state.lastName,
            phone_number: this.state.phone_number,
            problem_description: this.state.problem_description,
            type_equipment_id: this.state.type_equipment,
            part_affected: this.state.part_affected,
            is_emergency: this.state.is_emergency,
            type_frozen_id: this.state.frozen,
            product_pulled: this.state.product_pulled,
            alarm_code: this.state.alarm_code === [] ? false: this.state.alarm_code?.toString(),
            hours_operation: this.state.hours_operation_start + this.state.hours_operation_end,
            email: this.state.email,

            authorization_code: this.state.authorization_code,
            description_alarm_code: this.state.description_alarm_code,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        };
        this.setState({processSuccess:true});
        const errors = this.validate(params);
        if(Object.keys(errors).length === 0){
            params.alarm_code = false;
            params.hours_operation = 1;
            params.frozen = false;
            params.alarm_code = false;
            this.worxflowServices.createTrackstore(params).then(resp => {
                console.log("createTrackstore - Response: " , resp);
                if(resp.status_code === 200){
                    this.setState({processSuccess: true});
                    this.toast.show({severity: 'success', summary: 'WorkOrder', detail: 'Save work order'});
                    setTimeout(() => {
                        this.props.moveAtMenuOption('ListTrackStore');
                    }, 2500);
                } else {
                    this.toast.show({severity: 'error', summary: 'Error on service', detail: '/trackstore/create/'});
                }
            }).catch(error => {
                this.toast.show({severity: 'error', summary: 'Error on service', detail: error.message});
                console.error("Error en el servicio: ", error);
            });

        } else {
            this.setState({errors, processSuccess:false});
            console.log("Errors");
        }
    }
    validate = (data) => {
        let errors = {};
        if (!data.store_id) {
            errors.storeNameId = 'Store name is mandatory.';
        }
        if (!data.store_number) {
            errors.store_number = 'Store number is mandatory.';
        }
        if (!data.firstName) {
            errors.firstName = 'First name is mandatory.';
        }
        if (!data.lastName) {
            errors.lastName = 'Last name is mandatory.';
        }
        if (!data.problem_description) {
            errors.problem_description = 'Problem description is mandatory.';
        }
        if (!data.phone_number) {
            errors.phone_number = 'Mobile phone is mandatory.';
        }
        if(data.is_emergency === null){
            errors.is_emergency = 'Emergency is mandatory.';
        }
        if(data.product_pulled === null){
            errors.product_pulled = 'Product pulled is mandatory.';
        }
        if(!data.email){
            errors.email = 'Email is mandatory.';
        } else if(!validateEmail(data.email)) {
            errors.email = 'Email is not valid.';
        }
        /*
        if (!data.vnet_number) {
            errors.vnet_number = 'Vnet number is mandatory.';
        }
        if (!data.authorization_code) {
            errors.authorization_code = 'Authorization code is mandatory.';
        }
        if (!data.nte) {
            errors.NTE = 'NTE is mandatory.';
        }
        if (!data.name_call.trim()) {
            errors.first_name = 'First name is mandatory.';
        }
        if (!data.type_equipment) {
            errors.type_equipment = 'Type equipment is mandatory.';
        }
        if (!data.part_affected) {
            errors.part_affected = 'Part affected is mandatory.';
        }
        if (!data.alarm_code) {
            errors.alarm_code = 'Alarm code is mandatory.';
        }
        if (!data.description_alarm_code) {
            errors.description_alarm_code = 'Description alarm code is mandatory.';
        }
        if (!data.hours_operation) {
            errors.hours_operation_start = 'Hours operation is mandatory.';
            errors.hours_operation_end = 'Hours operation is mandatory.';
        }
        if (!data.hours_operation_end) {
            errors.hours_operation_end = 'Hours operation is mandatory.';
        }
        */

        return errors;
    };

    showModal = modal => () =>{
        this.setState({ [modal]: true});
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        this.setState({
            [showModal]:true,
            actionToConfirm: action})
    }

    submitStoreNameHandler = () => {
        const {storeNameForm} = this.state;
        const params={storeNameForm};
        const errors = this.validateStoreNameHandler(params);
        if(Object.keys(errors).length === 0){
            this.hideDialogs("editStoreNameDialog")
        } else {
            this.setState({errors, displayConfirm: false});
        }
    }
    submitStoreNumberHandler = () => {
        const {
            storeNameForm,
            nameForm,
            address1Form,
            address2Form,
            phoneForm,
            leadSourceForm,
            cityForm,
            stateForm,
            zipForm,
            short_numberForm,
            emailForm,
            firstNameForm,
            lastNameForm,
            list_store_numbers,
            store_numbers,
        } = this.state;

        const params= {
            storeNameId: storeNameForm?.value !== undefined ? storeNameForm?.value: storeNameForm,
            nameForm,
            address1Form,
            address2Form,
            phoneForm,
            leadSourceForm,
            cityForm,
            stateForm,
            zipForm,
            short_numberForm,
            emailForm,
            firstNameForm,
            lastNameForm,
        };
        const errors = this.validateStoreNumberHandler(params);
        if(Object.keys(errors).length === 0){
            this.hideDialogs("editStoreNumberDialog")
            const paramsChild = {
                store_id: params.storeNameId,
                name: storeNameForm?.value !== undefined ? storeNameForm?.label: storeNameForm,
                short_name: storeNameForm?.value !== undefined ? storeNameForm?.label: storeNameForm,
                short_number: short_numberForm,
                address_1: address1Form,
                address_2: address2Form,
                phone: phoneForm,
                email: emailForm,
                lead_source: leadSourceForm,
                first: firstNameForm,
                last: lastNameForm,
                company: '',
                city: cityForm,
                state: stateForm,
                zip: zipForm,
            };
            if (typeof params.storeNameId === 'string') {
                const paramsParent = {name: params.storeNameId}
                localStorage.removeItem('localStores');
                this.worxflowServices.saveStoreParent(paramsParent).then(resp => {
                    if(resp.status === "success"){
                        this.loadStoreParent();
                        const storeName = {label: resp.data.name, value: resp.data.id}
                        setTimeout(()=>{
                            this.setState({storeNameId: storeName});
                        },1000);
                        paramsChild.store_id = resp.data.id;
                        this.worxflowServices.saveStoreChild(paramsChild).then(resp => {
                            if(resp.status === "success"){
                                list_store_numbers.push(resp.data);
                                store_numbers.push({label: resp.data['short_number'], value: resp.data.id});
                                this.setState({
                                    list_store_numbers,
                                    store_numbers,
                                    selectedStore: resp.data,
                                    storeNumberId: resp.data.id,
                                    errors: {}
                                });
                            }
                        }).catch(error => {
                            console.error("Service error: ", error);
                        });
                    }
                }).catch(error => {
                    console.error("Service error: ", error);
                });
            } else {
                this.worxflowServices.saveStoreChild(paramsChild).then(resp => {
                    if(resp.status === "success"){
                        list_store_numbers.push(resp.data);
                        store_numbers.push({label: resp.data['short_number'], value: resp.data.id});
                        this.setState({
                            list_store_numbers,
                            store_numbers,
                            selectedStore: resp.data,
                            storeNumberId: resp.data.id,
                            errors: {}
                        });
                    }
                }).catch(error => {
                    console.error("Service error: ", error);
                });
            }
        } else {
            this.setState({errors, displayConfirm: false});
        }
    }
    submitTypeEquipment = () => {
        const {partAffectedForm} = this.state;
        const params={partAffectedForm};
        const errors = this.validatePartAffectedHandler(params);
        if(Object.keys(errors).length === 0) {
            const params = {name: partAffectedForm};
            this.worxflowServices.saveTypeEquipment(params).then(resp => {
                this.loadListTypeEquipment();
                setTimeout(()=>{
                    this.setState({frozen: partAffectedForm});
                    this.toast.show({severity: 'success', summary: 'Frozen', detail: 'Save type frozen'});
                    this.hideDialogs("partAffectedDialog")
                },800);
            }).catch(error => {
                console.error("Error en el servicio getChild: ", error);
                this.toast.show({severity: 'error', summary: 'Error', detail: 'Error service'});
            });
        } else {
            this.setState({errors, displayConfirm: false});
        }

    }
    submitFrozenHandler = () => {
        const {frozenForm} = this.state;
        const params = {frozenForm};
        const errors = this.validateFrozenHandler(params);
        if (Object.keys(errors).length === 0) {
            const params = {name: frozenForm};
            this.worxflowServices.saveTypeFrozen(params).then(resp => {
                this.loadListFrozen();
                setTimeout(()=>{
                    this.setState({frozen: frozenForm});
                    this.toast.show({severity: 'success', summary: 'Frozen', detail: 'Save type frozen'});
                    this.hideDialogs("frozenDialog");
                },800);
            }).catch(error => {
                console.error("Error en el servicio getChild: ", error);
                this.toast.show({severity: 'error', summary: 'Error', detail: 'Error service'});
            });
        } else {
            this.setState({errors, displayConfirm: false});
        }
    }

    validateStoreNameHandler = (data) => {
        let errors = {};
        if (!data.storeNameForm) {
            errors.storeNameForm = 'Store name is mandatory.';
        }
        return errors;
    };
    validateStoreNumberHandler = (data) => {
        let errors = {};
        if(!data.storeNameId){
            errors.storeNameId = "Store is mandatory.";
        }
        if (!data.address1Form) {
            errors.address1Form = 'Address 1 is mandatory.';
        }
        if (!data.cityForm) {
            errors.cityForm = 'City is mandatory.';
        }
        if (!data.stateForm) {
            errors.stateForm = 'State is mandatory.';
        }
        if (!data.nameForm) {
            errors.nameForm = 'Name is mandatory.';
        }
        if (!data.phoneForm) {
            errors.phoneForm = 'Phone is mandatory.';
        }
        if (!data.zipForm) {
            errors.zipForm = 'Zip is mandatory.';
        }
        if(!data.nameForm){
            errors.nameForm = 'Name is mandatory.';
        }
        if(!data.emailForm){
            errors.emailForm = 'Email is mandatory.';
        }
        if(!data.short_numberForm){
            errors.short_numberForm = 'Short number is mandatory.';
        }
        if(!data.firstNameForm){
            errors.firstNameForm = 'First name is mandatory.';
        }
        if(!data.lastNameForm){
            errors.lastNameForm = 'Last name is mandatory.';
        }

        return errors;
    };
    validatePartAffectedHandler = (data) => {
        let errors = {};
        if (!data.partAffectedForm) {
            errors.partAffectedForm = 'Part is mandatory.';
        }
        return errors;
    };
    validateFrozenHandler = (data) => {
        let errors = {};
        if (!data.frozenForm) {
            errors.frozenForm = 'Frozen is mandatory.';
        }
        return errors;
    };

    hideDialogs(nameDialog){
        this.setState({
            [nameDialog]: false,
            displayConfirm: false,
        });
    }

    templateStores(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    searchStore(event) {
        setTimeout(() => {
            let filteredStores;
            if (!event.query.trim().length) {
                filteredStores = [...this.state.stores];
            } else {
                filteredStores = this.state.stores.filter((country) => {
                    return country.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredStores });
        }, 250);
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        )
        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        )
        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={(e) => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        )

        const storeNameForm = (
            <Dialog header="Register Store" visible={this.state.editStoreNameDialog} modal={true}
                    style={{width: '350px'}}
                    contentStyle={{minHeight: '70px'}}
                    footer={dialogFooter('editStoreNameDialog', this.submitStoreNameHandler, 'Submit')}
                    onHide={this.hideModal('editStoreNameDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-4 center-card">
                        Store name: *
                    </div>
                    <div className="col-12 md:col-8">
                        <InputText
                            className={this.state.errors?.storeNameForm !== undefined ? "p-invalid" : ""}
                            placeholder="Store name"
                            name="storeNameForm"
                            onChange={this.changeHandler}
                            value={this.state.storeNameForm}
                        />
                        {this.state.errors?.storeNameForm !== undefined ?
                            <small className="offline">{this.state.errors?.storeNameForm}</small>
                            : ""
                        }
                    </div>
                </div>
            </Dialog>
        );

        const storeNumberForm = (
            <Dialog header="Register Store" visible={this.state.editStoreNumberDialog} modal={true}
                    style={{width: '70vw'}}
                    contentStyle={{minHeight: '200px'}}
                    footer={dialogFooter('editStoreNumberDialog', this.submitStoreNumberHandler, 'Submit')}
                    onHide={this.hideModal('editStoreNumberDialog')}>
                <div className="grid p-fluid">
                    <div className="col-6 md:col-3">
                        Add new store: *
                    </div>
                    <div className="col-6 md:col-3">
                        <AutoComplete
                                className={this.state.errors?.storeNameId !== undefined ? "form-element p-invalid" : "form-element "}
                                placeholder="Select a store"
                                name="storeNameForm"
                                suggestions={this.state.filteredStores}
                                value={this.state.storeNameForm}
                                field="label" dropdown
                                itemTemplate={this.templateStores}
                                completeMethod={this.searchStore}
                                onChange={this.changeHandler} />
                        {this.state.errors?.storeNameId !== undefined ?
                            <small className="offline">{this.state.errors?.storeNameId}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Store number: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.short_numberForm !== undefined ? "p-invalid" : ""}
                            placeholder="Short number"
                            name="short_numberForm"
                            onChange={this.changeHandler}
                            value={this.state.short_numberForm}
                        />
                        {this.state.errors?.short_numberForm !== undefined ?
                            <small className="offline">{this.state.errors?.short_numberForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Store Name: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.nameForm !== undefined ? "p-invalid" : ""}
                            placeholder="Store Name"
                            name="nameForm"
                            onChange={this.changeHandler}
                            value={this.state.nameForm}
                        />
                        {this.state.errors?.nameForm !== undefined ?
                            <small className="offline">{this.state.errors?.nameForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Address 1: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.address1Form !== undefined ? "p-invalid" : ""}
                            placeholder="Address 1"
                            name="address1Form"
                            onChange={this.changeHandler}
                            value={this.state.address1Form}
                        />
                        {this.state.errors?.address1Form !== undefined ?
                            <small className="offline">{this.state.errors?.address1Form}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Address 2:
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.address2Form !== undefined ? "p-invalid" : ""}
                            placeholder="Address 2"
                            name="address2Form"
                            onChange={this.changeHandler}
                            value={this.state.address2Form}
                        />
                    </div>
                    <div className="col-6 md:col-3">
                        First Name: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.firstNameForm !== undefined ? "p-invalid" : ""}
                            placeholder="First Name"
                            name="firstNameForm"
                            onChange={this.changeHandler}
                            value={this.state.firstNameForm}
                        />
                        {this.state.errors?.firstNameForm !== undefined ?
                            <small className="offline">{this.state.errors?.firstNameForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Last Name: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.lastNameForm !== undefined ? "p-invalid" : ""}
                            placeholder="Last Name"
                            name="lastNameForm"
                            onChange={this.changeHandler}
                            value={this.state.lastNameForm}
                        />
                        {this.state.errors?.lastNameForm !== undefined ?
                            <small className="offline">{this.state.errors?.lastNameForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        City: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.cityForm !== undefined ? "p-invalid" : ""}
                            placeholder="City"
                            name="cityForm"
                            onChange={this.changeHandler}
                            value={this.state.cityForm}
                        />
                        {this.state.errors?.cityForm !== undefined ?
                            <small className="offline">{this.state.errors?.cityForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        State: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.stateForm !== undefined ? "p-invalid" : ""}
                            placeholder="State"
                            name="stateForm"
                            onChange={this.changeHandler}
                            value={this.state.stateForm}
                        />
                        {this.state.errors?.stateForm !== undefined ?
                            <small className="offline">{this.state.errors?.stateForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                    </div>
                    <div className="col-6 md:col-3">

                    </div>
                    <div className="col-6 md:col-3">
                        Phone: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputMask
                            className={this.state.errors?.phoneForm !== undefined ? "p-invalid" : ""}
                            placeholder="Phone"
                            mask="(999) 999-9999"
                            name="phoneForm"
                            onChange={this.changeHandler}
                            value={this.state.phoneForm}
                        />
                        {this.state.errors?.phoneForm !== undefined ?
                            <small className="offline">{this.state.errors?.phoneForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Email: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.emailForm !== undefined ? "p-invalid" : ""}
                            placeholder="Email"
                            name="emailForm"
                            onChange={this.changeHandler}
                            value={this.state.emailForm}
                        />
                        {this.state.errors?.emailForm !== undefined ?
                            <small className="offline">{this.state.errors?.emailForm}</small>
                            : ""
                        }
                    </div>
                    <div className="col-6 md:col-3">
                        Zip: *
                    </div>
                    <div className="col-6 md:col-3">
                        <InputText
                            className={this.state.errors?.zipForm !== undefined ? "p-invalid" : ""}
                            placeholder="Zip"
                            name="zipForm"
                            onChange={this.changeHandler}
                            value={this.state.zipForm}
                        />
                        {this.state.errors?.zipForm !== undefined ?
                            <small className="offline">{this.state.errors?.zipForm}</small>
                            : ""
                        }
                    </div>
                </div>
            </Dialog>
        );

        const partAffectedForm = (
            <Dialog header="Part Affected" visible={this.state.partAffectedDialog} modal={true}
                    style={{width: '350px'}}
                    contentStyle={{minHeight: '70px'}}
                    footer={dialogFooter('partAffectedDialog', this.submitTypeEquipment, 'Submit')}
                    onHide={this.hideModal('partAffectedDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-4 center-card">
                        Part: *
                    </div>
                    <div className="col-12 md:col-8">
                        <InputText
                            className={this.state.errors?.partAffectedForm !== undefined ? "p-invalid" : ""}
                            placeholder="Part"
                            name="partAffectedForm"
                            onChange={this.changeHandler}
                            value={this.state.partAffectedForm}
                        />
                        {this.state.errors?.partAffectedForm !== undefined ?
                            <small className="offline">{this.state.errors?.partAffectedForm}</small>
                            : ""
                        }
                    </div>
                </div>
            </Dialog>
        );
        const frozenForm = (
            <Dialog header="Frozen Affected" visible={this.state.frozenDialog} modal={true}
                    style={{width: '350px'}}
                    contentStyle={{minHeight: '70px'}}
                    footer={dialogFooter('frozenDialog', this.submitFrozenHandler, 'Submit')}
                    onHide={this.hideModal('frozenDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 md:col-4 center-card">
                        Frozen name: *
                    </div>
                    <div className="col-12 md:col-8">
                        <InputText
                            className={this.state.errors?.frozenForm !== undefined ? "p-invalid" : ""}
                            placeholder="Frozen name"
                            name="frozenForm"
                            onChange={this.changeHandler}
                            value={this.state.frozenForm}
                        />
                        {this.state.errors?.frozenForm !== undefined ?
                            <small className="offline">{this.state.errors?.frozenForm}</small>
                            : ""
                        }
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>

                <Card height="height-100">
                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="storeNameId" className="p-d-block">Store Name *: </label>
                            <AutoComplete
                                className={this.state.errors?.storeNameId !== undefined ? "form-element p-invalid" : "form-element "}
                                placeholder="Select a store"
                                name="storeNameId"
                                suggestions={this.state.filteredStores}
                                value={this.state.storeNameId}
                                field="label" dropdown
                                itemTemplate={this.templateStores}
                                completeMethod={this.searchStore}
                                onChange={this.changeHandler}
                                onSelect={this.searchObjectSelected}
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.storeNameId !== undefined ?
                                <small className="offline">{this.state.errors?.storeNameId}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="storeNumberId" className="p-d-block">Store Number *:</label>
                            <div className="p-fluid grid">
                                <div className="p-field col-11" style={{paddingRight: "0"}}>
                                    <Dropdown id="storeNumberId"
                                              className={this.state.errors?.store_number !== undefined ? "form-element p-invalid" : "form-element "}
                                              placeholder="Select a store number"
                                              name="storeNumberId"
                                              options={this.state.store_numbers}
                                              value={this.state.storeNumberId}
                                              filter filterBy="label"
                                              onChange={this.searchChildSelected}
                                              onFocus={this.checkStoreName}
                                              disabled={this.state.processSuccess}
                                    />
                                </div>
                                <div className="p-field col-1 ta-right" style={{paddingLeft: "0"}}>
                                    <Button icon="pi pi-plus" style={{fontSize: 12}}
                                            onClick={this.showModal('editStoreNumberDialog')}/>
                                </div>
                            </div>
                            {this.state.errors?.store_number !== undefined ?
                                <small className="offline">{this.state.errors?.store_number}</small>
                                : ""
                            }
                        </div>

                        {this.state.storeNumberId !== '' &&
                        <div className="p-field col-12">
                            <div className="grid">
                                <div className="p-field col-12 mt-10">
                                    <label className="p-d-block">Address: <b>{this.state.selectedStore?.address_1}
                                                            <br/> {this.state.selectedStore?.address_2}
                                                            </b></label>
                                </div>
                                <div className="p-field col-3">
                                    <label className="p-d-block">City: <b>{this.state.selectedStore?.city}</b></label>
                                </div>
                                <div className="p-field col-3">
                                    <label className="p-d-block">State: <b>{this.state.selectedStore?.state}</b></label>
                                </div>
                                <div className="p-field col-3">
                                    <label className="p-d-block">Zip: <b>{this.state.selectedStore?.zip}</b></label>
                                </div>
                                <div className="p-field col-3">
                                    <label className="p-d-block">Store Phone: <b>{this.state.selectedStore?.phone}</b></label>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="firstName" className="p-d-block">Contact:</label>
                            <InputText
                                className={this.state.errors?.firstName !== undefined ? "p-invalid" : ""}
                                placeholder="First name"
                                name="firstName"
                                onChange={this.changeHandler}
                                value={this.state.firstName}
                                id="firstName"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.firstName !== undefined ?
                                <small className="offline">{this.state.errors?.firstName}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <InputText
                                style={{marginTop: 13}}
                                className={this.state.errors?.lastName !== undefined ? "p-invalid" : ""}
                                placeholder="Last name"
                                name="lastName"
                                onChange={this.changeHandler}
                                value={this.state.lastName}
                                id="contact"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.lastName !== undefined ?
                                <small className="offline">{this.state.errors?.lastName}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="firstName" className="p-d-block">Mobile number:</label>
                            <InputMask
                                className={this.state.errors?.phone_number !== undefined ? "p-invalid" : ""}
                                mask="(999) 999-9999"
                                placeholder="Mobile number"
                                name="phone_number"
                                onChange={this.changeHandler}
                                value={this.state.phone_number}
                                id="phone_number"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.phone_number !== undefined ?
                                <small className="offline">{this.state.errors?.phone_number}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="firstName" className="p-d-block">Email:</label>
                            <InputText
                                className={this.state.errors?.email !== undefined ? "p-invalid" : ""}
                                placeholder="Email"
                                name="email"
                                onChange={this.changeHandler}
                                value={this.state.email}
                                id="contact"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.email !== undefined ?
                                <small className="offline">{this.state.errors?.email}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12">
                            <label className="p-d-block">May I have a description of the problem?</label>
                            <InputTextarea
                                className={this.state.errors?.problem_description !== undefined ? "p-invalid" : ""}
                                value={this.state.problem_description}
                                name="problem_description"
                                onChange={this.changeHandler}
                                rows={3} cols={30}
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.problem_description !== undefined ?
                                <small className="offline">{this.state.errors?.problem_description}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="authorization_code" className="p-d-block">Authorization Code:</label>
                            <InputText
                                className={this.state.errors?.authorization_code !== undefined ? "p-invalid" : ""}
                                placeholder="N/A"
                                name="authorization_code"
                                onChange={this.changeHandler}
                                value={this.state.authorization_code}
                                id="authorization_code"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.authorization_code !== undefined ?
                                <small className="offline">{this.state.errors?.authorization_code}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="Vnet_number" className="p-d-block">PO number:</label>
                            <InputText
                                className={this.state.errors?.vnet_number !== undefined ? "p-invalid" : ""}
                                placeholder="N/A"
                                name="vnet_number"
                                onChange={this.changeHandler}
                                value={this.state.vnet_number}
                                id="vnet_number"
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.vnet_number !== undefined ?
                                <small className="offline">{this.state.errors?.vnet_number}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="NTE" className="p-d-block">NTE:</label>
                            <InputText
                                className={this.state.errors?.NTE !== undefined ? "p-invalid" : ""}
                                placeholder="N/A"
                                name="NTE"
                                onChange={this.changeHandler}
                                value={this.state.NTE}
                                id="NTE"
                                disabled={this.state.processSuccess}
                                tooltip="Not to exceed" tooltipOptions={{position: 'top'}}
                            />
                            {this.state.errors?.NTE !== undefined ?
                                <small className="offline">{this.state.errors?.NTE}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12">
                            <label htmlFor="part_affected" className="p-d-block">Part affected: </label>
                            <InputText
                                className={this.state.errors?.part_affected ? "p-invalid" : ""}
                                placeholder="N/A"
                                name="part_affected"
                                onChange={this.changeHandler}
                                value={this.state.part_affected}
                                id="type_equipment"
                                tooltip="Case, System # (if known):" tooltipOptions={{position: 'top'}}
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.part_affected !== undefined ?
                                <small className="offline">{this.state.errors?.part_affected}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="frozen" className="p-d-block">Frozen</label>
                            <div className="p-fluid grid">
                                <div className="p-field col-11" style={{paddingRight: "0"}}>
                                    <Dropdown id="frozen" className="form-element" placeholder="N/A"
                                              name="frozen"
                                              options={this.state.frozenOption}
                                              value={this.state.frozen}
                                              onChange={this.changeHandler} optionLabel="label"
                                              disabled={this.state.processSuccess}
                                    />
                                </div>
                                <div className="p-field col-1 ta-right" style={{paddingLeft: "0"}}>
                                    <Button icon="pi pi-plus" style={{fontSize: 12}}
                                            onClick={this.showModal('frozenDialog')}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6">
                            <label htmlFor="type_equipment" className="p-d-block">What type of equipment?</label>
                            <div className="p-fluid grid">
                                <div className="p-field col-11" style={{paddingRight: "0"}}>
                                    <Dropdown className={this.state.errors?.type_equipment !== undefined ? "form-element p-invalid" : "form-element"}
                                              placeholder="N/A"
                                              name="type_equipment"
                                              options={this.state.partOption}
                                              value={this.state.type_equipment}
                                              onChange={this.changeHandler}
                                              disabled={this.state.processSuccess}
                                    />
                                </div>
                                <div className="p-field col-1 ta-right" style={{paddingLeft: "0"}}>
                                    <Button icon="pi pi-plus" style={{fontSize: 12}}
                                            onClick={this.showModal('partAffectedDialog')}/>
                                </div>
                            </div>
                            {this.state.errors?.type_equipment !== undefined ?
                                <small className="offline">{this.state.errors?.type_equipment}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="is_emergency" className="p-d-block">Is emergency</label>
                            <SelectButton value={this.state.is_emergency} options={this.state.emergencyOption}
                                          name="is_emergency"
                                          onChange={this.changeHandler}
                                          disabled={this.state.processSuccess}
                                          className="button-blue"
                            />
                            {this.state.errors?.is_emergency !== undefined ?
                                <small className="offline">{this.state.errors?.is_emergency}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="product_pulled" className="p-d-block">Product pulled</label>
                            <SelectButton value={this.state.product_pulled} options={this.state.emergencyOption}
                                          name="product_pulled"
                                          onChange={this.changeHandler}
                                          disabled={this.state.processSuccess}
                                          className="button-blue"
                            />
                            {this.state.errors?.product_pulled !== undefined ?
                                <small className="offline">{this.state.errors?.product_pulled}</small>
                                : ""
                            }
                        </div>
                        <div className="p-field col-12 lg:col-4 md:col-6">
                            <label htmlFor="alarm_code" className="p-d-block">Are there any alarm codes?</label>
                            <SelectButton value={this.state.alarm_code} options={this.state.emergencyOption}
                                          onChange={(e) => this.setState({alarm_code: e.value})}
                                          disabled={this.state.processSuccess}
                                          className="button-blue"
                            />
                        </div>
                        <div className="p-field col-12 lg:col-8 md:col-12">
                            <label htmlFor="alarm_code" className="p-d-block">Description alarm codes</label>
                            <InputTextarea
                                className={this.state.errors?.description_alarm_code !== undefined ? "p-invalid" : ""}
                                value={this.state.description_alarm_code}
                                name="description_alarm_code"
                                onChange={this.changeHandler}
                                rows={2} cols={30}
                                disabled={this.state.processSuccess}
                            />
                            {this.state.errors?.description_alarm_code !== undefined ?
                                <small className="offline">{this.state.errors?.description_alarm_code}</small>
                                : ""
                            }
                        </div>

                        <div className="p-field col-12 lg:col-8 md:col-12" style={{display:"none"}}>
                            <label htmlFor="hours_operation" className="p-d-block">What are your hours of operation to
                                send our technician?</label>
                            <div className="grid">
                                <div className="col-6" style={{marginTop: '0.5rem'}}>
                                    <Calendar
                                        className={this.state.errors?.hours_operation_start !== undefined ? "p-invalid" : ""}
                                        id="hours_operation_start" value={this.state.hours_operation_start}
                                        name="hours_operation_start"
                                        onChange={this.changeHandler}
                                        timeOnly hourFormat="12"
                                        disabled={this.state.processSuccess}
                                    />
                                    {this.state.errors?.hours_operation_start !== undefined ?
                                        <small className="offline">{this.state.errors?.hours_operation_start}</small>
                                        : ""
                                    }
                                </div>
                                <div className="col-6" style={{marginTop: '0.5rem'}}>
                                    <Calendar
                                        className={this.state.errors?.hours_operation_end !== undefined ? "p-invalid" : ""}
                                        id="hours_operation_end" value={this.state.hours_operation_end}
                                        name="hours_operation_end"
                                        onChange={this.changeHandler}
                                        timeOnly hourFormat="12"
                                        disabled={this.state.processSuccess}
                                    />
                                    {this.state.errors?.hours_operation_end !== undefined ?
                                        <small className="offline">{this.state.errors?.hours_operation_end}</small>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-8 p-offset-2 lg:col-4 md:col-4 lg:col-offset-4 md:col-offset-4" style={{marginTop: '0.5rem'}}>
                                    <Button label="Submit" onClick={this.submitHandler} disabled={this.state.processSuccess}/>
                                </div>
                            </div>
                        </div>

                        {this.state.processSuccess &&
                            <div className="p-field col-12 mt-30">
                                <label>{this.state.name_call} I will get this sent immediately for you and will follow up with you
                                    as son as the agent is on his way to your store. Again my name is
                                    AGENT_NAME.</label>
                                <label className="p-d-block mt-20">Thank you and have a great day!</label>
                            </div>
                        }
                    </div>
                </Card>

                {storeNameForm}
                {storeNumberForm}
                {partAffectedForm}
                {frozenForm}
                {confirmDialog}
            </div>
        )
    }

}

export default WorkOder;
